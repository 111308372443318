export class IamApis {
  static def =[
     {
       "cmd": "postUserRegistrationNonce",
       "cmdType": "unsecured",
       "type": "POST",
       "accepts": "application/vnd.com.covisint.platform.person.password.account.v1+json",
       "contentType": "application/vnd.com.covisint.platform.person.password.account.v1+json",
       "call": "/registration/v1/registrations/persons/registration"
     },
     {
       "cmd": "postPersonRequestNonce",
       "cmdType": "unsecured",
       "type": "POST",
       "accepts": "application/vnd.com.covisint.platform.person.request.v1+json",
       "contentType": "application/vnd.com.covisint.platform.person.request.v1+json",
       "call": "/registration/v1/registrations/persons/requests"
     },
     {
       "cmd": "initiateNonce",
       "cmdType": "unsecured",
       "type": "GET",
       "accepts": "application/json",
       "call": "/registration/v1/registrations/open/initiate"
     },
     {
       "cmd": "getOrganizationNonce",
       "cmdType": "unsecured",
       "type": "GET",
       "accepts": "application/vnd.com.covisint.platform.organization.v1+json",
       "call": "/registration/v1/registrations/organizations/{organizationId}"
     },
     {
       "cmd": "getOrganizationsNonce",
       "cmdType": "unsecured",
       "type": "GET",
       "accepts": "application/vnd.com.covisint.platform.organization.v1+json",
       "call": "/registration/v1/registrations/organizations?pageSize=200"
     },
     {
       "cmd": "getOrganizations",
       "cmdType": "secured",
       "call": "/organization/v3/organizations",
       "type": "GET",
       "accepts": "application/vnd.com.covisint.platform.organization.v1+json"
     },
     {
       "cmd": "getOrganizationsCount",
       "cmdType": "secured",
       "call": "/organization/v3/organizations/count",
       "type": "GET",
       "accepts": "text/plain"
     },
     {
       "cmd": "getOrganizationRequests",
       "cmdType": "unsecured",
       "call": "/organization/v3/organizations/requests",
       "type": "POST",
       "accepts": "application/vnd.com.covisint.platform.organization.request.v1+json",
       "contentType": "application/vnd.com.covisint.platform.organization.request.v1+json"
     },
     {
       "cmd": "approveOrganizationRequests",
       "call": "/organization/v3/organizations/requests/tasks/approve",
       "type": "POST",
       "accepts": "application/vnd.com.covisint.platform.organization.request.v1+json",
       "contentType": "application/vnd.com.covisint.platform.organization.request.v1+json"
     },
     {
       "cmd": "denyOrganizationRequests",
       "call": "/organization/v3/organizations/requests/tasks/deny",
       "type": "POST",
       "accepts": "application/vnd.com.covisint.platform.organization.request.v1+json",
       "contentType": "application/vnd.com.covisint.platform.organization.request.v1+json"
     },
     {
       "cmd": "getOrganization",
       "cmdType": "secured",
       "call": "/organization/v3/organizations/{organizationId}",
       "type": "GET",
       "accepts": "application/vnd.com.covisint.platform.organization.v1+json"
     },
     {
       "cmd": "updateOrganization",
       "cmdType": "secured",
       "call": "/organization/v3/organizations/{organizationId}",
       "type": "PUT",
       "accepts": "application/vnd.com.covisint.platform.organization.v1+json",
       "contentType": "application/vnd.com.covisint.platform.organization.v1+json"
     },
     {
       "cmd": "getOrganizationHierarchy",
       "cmdType": "secured",
       "call": "/organization/v3/organizations/{organizationId}/hierarchy",
       "type": "GET",
       "accepts": "application/vnd.com.covisint.platform.reference.hierarchy.v1+json;includedivision=true"
     },
     {
       "cmd": "getPersons",
       "cmdType": "secured",
       "call": "/person/v3/persons",
       "type": "GET",
       "accepts": "application/vnd.com.covisint.platform.person.v1+json"
     },
     {
       "cmd": "createPerson",
       "call": "/person/v3/persons",
       "type": "POST",
       "accepts": "application/vnd.com.covisint.platform.person.v1+json",
       "contentType": "application/vnd.com.covisint.platform.person.v1+json"
     },
     {
       "cmd": "getPersonsAdmins",
       "cmdType": "secured",
       "call": "/person/v3/persons",
       "type": "GET",
       "accepts": "application/vnd.com.covisint.platform.person.v1+json;securityadmin=true"
     },
     {
       "cmd": "countPersons",
       "cmdType": "secured",
       "call": "/person/v3/persons/count",
       "type": "GET",
       "accepts": "text/plain"
     },
     {
       "cmd": "getPersonRolesOnly",
       "cmdType": "secured",
       "call": "/person/v3/persons/roles/{personId}",
       "type": "GET",
       "accepts": "application/vnd.com.covisint.platform.person.role.v1+json;onlyroles=true"
     },
     {
       "cmd": "getPersonRoles",
       "cmdType": "secured",
       "call": "/person/v3/persons/roles/{personId}",
       "type": "GET",
       "accepts": "application/vnd.com.covisint.platform.person.role.v1+json"
     },
     {
       "cmd": "getTemplates",
       "cmdType": "secured",
       "call": "/notification/v1/notification/templates",
       "type": "GET",
       "accepts": "application/vnd.com.covisint.platform.notificationtemplate.v1+json"
     },
     {
       "cmd": "registerPerson",
       "cmdType": "unsecured",
       "call": "/person/v3/persons/registration",
       "type": "POST",
       "accepts": "application/vnd.com.covisint.platform.person.password.account.v1+json",
       "contentType": "application/vnd.com.covisint.platform.person.password.account.v1+json"
     },
     {
       "cmd": "getRegistrationStatus",
       "call": "/person/v3/persons/registration/status",
       "type": "GET",
       "accepts": "application/vnd.com.covisint.platform.person.password.account.v1+json"
     },
     {
       "cmd": "validateRegistration",
       "cmdType": "unsecured",
       "call": "/person/v3/persons/registration/validate",
       "type": "POST",
       "accepts": "application/vnd.com.covisint.platform.person.password.account.v1+json",
       "contentType": "application/vnd.com.covisint.platform.person.password.account.v1+json"
     },
     {
       "cmd": "getPersonAttributes",
       "call": "/person/v3/persons/{personId}/templates",
       "type": "GET",
       "accepts": "application/vnd.com.covisint.platform.person.attribute.template.v1+json"
     },
     {
       "cmd": "updatePersonAttributes",
       "call": "/person/v3/persons/{personId}/templates",
       "type": "PUT",
       "accepts": "application/vnd.com.covisint.platform.person.attribute.template.v1+json",
       "contentType": "application/vnd.com.covisint.platform.person.attribute.template.v1+json"
     },
     {
       "cmd": "deletePersonAttributes",
       "call": "/person/v3/persons/{personId}/templates",
       "type": "DELETE",
       "accepts": "application/vnd.com.covisint.platform.person.attribute.template.v1+json"
     },
     {
       "cmd": "validatePassword",
       "cmdType": "secured",
       "call": "/person/v3/persons/password/validate",
       "type": "POST",
       "accepts": "application/vnd.com.covisint.platform.password.validation.response.v1+json",
       "contentType": "application/vnd.com.covisint.platform.password.validation.v1+json"
     },
     {
       "cmd": "getPerson",
       "cmdType": "secured",
       "call": "/person/v3/persons/{personId}",
       "type": "GET",
       "accepts": "application/vnd.com.covisint.platform.person.v1+json;lastlogindate=true"
     },
     {
       "cmd": "getOrgPackagesByOrgId",
       "cmdType": "secured",
       "call": "/service/v3/applications/organizations/{orgid}",
       "type": "GET",
       "accepts": "application/vnd.com.covisint.platform.service.application.v1+json"
     },
     {
       "cmd": "updatePerson",
       "cmdType": "secured",
       "call": "/person/v3/persons/{personId}",
       "type": "PUT",
       "accepts": "application/vnd.com.covisint.platform.person.v1+json",
       "contentType": "application/vnd.com.covisint.platform.person.v1+json"
     },
     {
       "cmd": "activatePerson",
       "call": "/person/v3/persons/tasks/activate",
       "type": "POST",
       "accepts": "application/vnd.com.covisint.platform.person.v1+json",
       "contentType": "application/vnd.com.covisint.platform.person.v1+json"
     },
     {
       "cmd": "suspendPerson",
       "cmdType": "secured",
       "call": "/person/v3/persons/tasks/suspend",
       "type": "POST"
     },
     {
       "cmd": "unsuspendPerson",
       "cmdType": "secured",
       "call": "/person/v3/persons/tasks/unsuspend",
       "type": "POST"
     },
     {
       "cmd": "getPersonInvitations",
       "call": "/person/v3/personInvitations",
       "type": "GET",
       "accepts": "application/vnd.com.covisint.platform.person.invitation.v1+json"
     },
     {
       "cmd": "createPersonInvitation",
       "call": "/person/v3/personInvitations/bulk",
       "type": "POST",
       "accepts": "application/vnd.com.covisint.platform.person.invitation.v1+json",
       "contentType": "application/vnd.com.covisint.platform.person.invitation.v1+json"
     },
     {
       "cmd": "getPersonInvitation",
       "cmdType": "unsecured",
       "call": "/person/v3/personInvitations/{invitationId}",
       "type": "GET",
       "accepts": "application/vnd.com.covisint.platform.person.invitation.v1+json"
     },
     {
       "cmd": "deletePersonInvitation",
       "cmdType": "unsecured",
       "call": "/person/v3/personInvitations/{invitationId}",
       "type": "DELETE",
       "accepts": "application/vnd.com.covisint.platform.person.invitation.v1+json"
     },
     {
       "cmd": "acceptPersonInvitation",
       "call": "/person/v3/personInvitations/tasks/accept",
       "type": "POST",
       "accepts": "application/vnd.com.covisint.platform.person.invitation.v1+json",
       "contentType": "application/vnd.com.covisint.platform.person.invitation.v1+json"
     },
     {
       "cmd": "getPersonRequests",
       "call": "/person/v3/requests",
       "type": "GET",
       "accepts": "application/vnd.com.covisint.platform.person.request.v1+json"
     },
     {
       "cmd": "createPersonRequest",
       "cmdType": "unsecured",
       "call": "/person/v3/requests",
       "type": "POST",
       "accepts": "application/vnd.com.covisint.platform.person.request.v1+json",
       "contentType": "application/vnd.com.covisint.platform.person.request.v1+json"
     },
     {
       "cmd": "getPersonRequest",
       "call": "/person/v3/requests/{requestId}",
       "type": "GET",
       "accepts": "application/vnd.com.covisint.platform.person.request.v1+json"
     },
     {
       "cmd": "deletePersonRequest",
       "call": "/person/v3/requests/{requestId}",
       "type": "DELETE",
       "accepts": "application/vnd.com.covisint.platform.person.request.v1+json"
     },
     {
       "cmd": "approvePersonRequest",
       "call": "/person/v3/requests/tasks/approve",
       "type": "POST",
       "accepts": "application/vnd.com.covisint.platform.person.request.v1+json",
       "contentType": "application/vnd.com.covisint.platform.person.request.v1+json"
     },
     {
       "cmd": "denyPersonRequest",
       "call": "/person/v3/requests/tasks/deny",
       "type": "POST",
       "accepts": "application/vnd.com.covisint.platform.person.request.v1+json",
       "contentType": "application/vnd.com.covisint.platform.person.request.v1+json"
     },
     {
       "cmd": "getPersonPassword",
       "call": "/person/v3/persons/{personId}/accounts/password",
       "type": "GET",
       "accepts": "application/vnd.com.covisint.platform.person.account.password.v1+json"
     },
     {
       "cmd": "createPersonPassword",
       "call": "/person/v3/persons/{personId}/accounts/password",
       "type": "PUT",
       "accepts": "application/vnd.com.covisint.platform.person.account.password.v1+json",
       "contentType": "application/vnd.com.covisint.platform.person.account.password.v1+json"
     },
     {
       "cmd": "updatePersonPassword",
       "call": "/person/v3/persons/{personId}/accounts/password",
       "type": "PUT",
       "accepts": "application/vnd.com.covisint.platform.person.account.password.v1+json",
       "contentType": "application/vnd.com.covisint.platform.person.account.password.v1+json"
     },
     {
       "cmd": "getServices",
       "cmdType": "secured",
       "call": "/service/v3/services",
       "type": "GET",
       "accepts": "application/vnd.com.covisint.platform.service.v1+json"
     },
     {
       "cmd": "createService",
       "call": "/service/v3/services",
       "cmdType": "secured",
       "type": "POST",
       "accepts": "application/vnd.com.covisint.platform.service.v1+json",
       "contentType": "application/vnd.com.covisint.platform.service.v1+json"
     },
     {
       "cmd": "getService",
       "call": "/service/v3/services/{serviceId}",
       "type": "GET",
       "accepts": "application/vnd.com.covisint.platform.service.v1+json"
     },
     {
       "cmd": "getPackageServices",
       "call": "/service/v3/packages/{packageId}/services",
       "type": "GET",
       "accepts": "application/vnd.com.covisint.platform.service.v1+json"
     },
     {
       "cmd": "getPackages",
       "call": "/service/v3/packages",
       "cmdType": "secured",
       "type": "GET",
       "accepts": "application/vnd.com.covisint.platform.package.v1+json"
     },
     {
       "cmd": "getPackageAdmins",
       "cmdType": "secured",
       "call": "/service/v3/packages/{packageId}/persons",
       "type": "GET",
       "accepts": "application/json",
       "dataType": "text"
     },
     {
       "cmd": "addPackageAdmins",
       "call": "/service/v3/packages/{packageId}/admin/add",
       "type": "POST",
       "accepts": "application/vnd.com.covisint.platform.package.resource.admin.v1+json",
       "contentType": "application/vnd.com.covisint.platform.package.resource.admin.v1+json"
     },
     {
       "cmd": "removePackageAdmins",
       "call": "/service/v3/packages/{packageId}/admin/remove",
       "type": "POST",
       "contentType": "application/vnd.com.covisint.platform.package.resource.admin.v1+json"
     },
     {
       "cmd": "createPackage",
       "call": "/service/v3/packages",
       "type": "POST",
       "cmdType": "secured",
       "accepts": "application/vnd.com.covisint.platform.package.v1+json",
       "contentType": "application/vnd.com.covisint.platform.package.v1+json"
     },
     {
       "cmd": "updatePackage",
       "call": "/service/v3/packages/{packageId}",
       "type": "PUT",
       "cmdType": "secured",
       "accepts": "application/vnd.com.covisint.platform.package.v1+json",
       "contentType": "application/vnd.com.covisint.platform.package.v1+json"
     },
     {
       "cmd": "countPackages",
       "call": "/service/v3/packages/count",
       "type": "GET",
       "cmdType": "secured",
       "accepts": "text/plain"
     },
     {
       "cmd": "getRequestablePersonPackages",
       "call": "/service/v3/packages/persons/{personId}/requestable",
       "type": "GET",
       "accepts": "application/vnd.com.covisint.platform.package.v1+json"
     },
     {
       "cmd": "getRequestableOrganizationPackages",
       "call": "/service/v3/packages/organizations/{organizationId}/requestable",
       "type": "GET",
       "accepts": "application/vnd.com.covisint.platform.package.v1+json"
     },
     {
       "cmd": "getPackage",
       "cmdType": "secured",
       "call": "/service/v3/packages/{packageId}",
       "type": "GET",
       "accepts": "application/vnd.com.covisint.platform.package.v1+json"
     },
     {
       "cmd": "assignService",
       "cmdType": "secured",
       "call": "/service/v3/packages/tasks/assignServiceMembership",
       "type": "POST"
     },
     {
       "cmd": "removeService",
       "cmdType": "secured",
       "call": "/service/v3/packages/tasks/removeServiceMembership",
       "type": "POST"
     },
     {
       "cmd": "getPersonPackages",
       "call": "/service/v3/persons/{personId}/packages",
       "type": "GET",
       "accepts": "application/vnd.com.covisint.platform.package.grant.v1+json"
     },
     {
       "cmd": "getPersonPackage",
       "call": "/service/v3/persons/{personId}/packages/{packageId}",
       "type": "GET",
       "accepts": "application/vnd.com.covisint.platform.package.grant.v1+json"
     },
     {
       "cmd": "grantPersonPackage",
       "call": "/service/v3/persons/{personId}/packages/{packageId}",
       "type": "PUT",
       "cmdType": "secured",
       "accepts": "application/vnd.com.covisint.platform.package.grant.v1+json",
       "contentType": "application/vnd.com.covisint.platform.package.grant.v1+json"
     },
     {
       "cmd": "getOrganizationPackages",
       "cmdType": "secured",
       "call": "/service/v3/organizations/{organizationId}/packages",
       "type": "GET",
       "accepts": "application/vnd.com.covisint.platform.package.grant.v1+json"
     },
     {
       "cmd": "getSAOHierarchy",
       "cmdType": "secured",
       "call": "/organization/v3/organizations/{orgId}/packages/{packageId}/saohierarchy",
       "type": "GET",
       "accepts": "application/vnd.com.covisint.platform.reference.sao.hierarchy.v1+json;includedivision=true"
     },
     {
       "cmd": "updateApplicationAuthority",
       "cmdType": "secured",
       "call": "/service/v3/packages/{packageId}/sao/organization/{orgId}",
       "type": "PUT"
     },
     {
       "cmd": "updateAllClaimValues",
       "cmdType": "secured",
       "call": "/service/v3/packages/grants/type/{claimType}/claims/{claimId}/all/values",
       "type": "POST",
       "accepts": "application/vnd.com.covisint.platform.package.grant.claim.v1+json",
       "contentType": "application/vnd.com.covisint.platform.package.grant.claim.v1+json"
     },
     {
       "cmd": "getOrganizationPackage",
       "cmdType": "secured",
       "call": "/service/v3/organizations/{organizationId}/packages/{packageId}",
       "type": "GET",
       "accepts": "application/vnd.com.covisint.platform.package.grant.v1+json"
     },
     {
       "cmd": "grantOrganizationPackage",
       "cmdType": "secured",
       "call": "/service/v3/organizations/{organizationId}/packages/{packageId}",
       "type": "PUT",
       "accepts": "application/vnd.com.covisint.platform.package.grant.v1+json",
       "contentType": "application/vnd.com.covisint.platform.package.grant.v1+json"
     },
     {
       "cmd": "revokeOrganizationPackage",
       "cmdType": "secured",
       "call": "/service/v3/organizations/{organizationId}/packages/{packageId}",
       "type": "DELETE"
     },
     {
       "cmd": "suspendOrganizationPackage",
       "cmdType": "secured",
       "call": "/service/v3/grants/tasks/organization/package/suspend",
       "type": "POST",
       "accepts": "application/vnd.com.covisint.platform.package.grant.status.request.v1+json",
       "contentType": "application/vnd.com.covisint.platform.package.grant.status.request.v1+json"
     },
     {
       "cmd": "unsuspendOrganizationPackage",
       "cmdType": "secured",
       "call": "/service/v3/grants/tasks/organization/package/unsuspend",
       "type": "POST",
       "accepts": "application/vnd.com.covisint.platform.package.grant.status.request.v1+json",
       "contentType": "application/vnd.com.covisint.platform.package.grant.status.request.v1+json"
     },
     {
       "cmd": "getPackageRequests",
       "call": "/service/v3/requests",
       "type": "GET",
       "accepts": "application/vnd.com.covisint.platform.package.request.v1+json;includedivision=true;includeclaimrequests=true;includeclaimvaluerequests=true;"
     },
     {
       "cmd": "createPackageRequest",
       "call": "/service/v3/requests",
       "type": "POST",
       "accepts": "application/vnd.com.covisint.platform.package.request.v1+json;includedivision=true;includeclaimrequests=true;includeclaimvaluerequests=true;",
       "contentType": "application/vnd.com.covisint.platform.package.request.v1+json;includedivision=true;includeclaimrequests=true;includeclaimvaluerequests=true;"
     },
     {
       "cmd": "getPackageRequest",
       "call": "/service/v3/requests/{requestId}",
       "type": "GET",
       "accepts": "application/vnd.com.covisint.platform.package.request.v1+json"
     },
     {
       "cmd": "deletePackageRequest",
       "call": "/service/v3/requests/{requestId}",
       "type": "DELETE",
       "accepts": "application/vnd.com.covisint.platform.package.request.v1+json"
     },
     {
       "cmd": "approvePackageRequest",
       "call": "/service/v3/requests/tasks/approve",
       "type": "POST"
     },
     {
       "cmd": "denyPackageRequest",
       "call": "/service/v3/requests/tasks/deny",
       "type": "POST"
     },
     {
       "cmd": "getPackageGrants",
       "call": "/service/v3/grants",
       "type": "GET",
       "accepts": "application/vnd.com.covisint.platform.package.grant.v1"
     },
     {
       "cmd": "getPersonClaims",
       "call": "/service/v3/persons/{personId}/claims?type=CODE",
       "type": "GET",
       "accepts": "application/vnd.com.covisint.platform.package.grant.claim.v1"
     },
     {
       "cmd": "getPersonPackageClaims",
       "cmdType": "secured",
       "call": "/service/v3/persons/{personId}/packages/{packageId}/claims",
       "type": "GET",
       "accepts": "application/vnd.com.covisint.platform.package.grant.claim.v1"
     },
     {
       "cmd": "getGroups",
       "call": "/group/v3/groups",
       "cmdType": "secured",
       "type": "GET",
       "accepts": "application/vnd.com.covisint.platform.group.v1+json;includeEntitlements"
     },
     {
       "cmd": "deleteGroupById",
       "call": "/group/v3/groups/{groupId}",
       "cmdType": "secured",
       "type": "DELETE",
       "accepts": "text/plain"
     },
     {
       "cmd": "groupTemplates",
       "call": "/group/v3/groupTemplates",
       "cmdType": "secured",
       "type": "GET",
       "accepts": "application/vnd.com.covisint.platform.group.template.v1+json;includeAttributeTypes=true"
     },
     {
       "cmd": "createGroupWithGroupTemplate",
       "call": "/group/v3/groupTemplates/{groupTemplateId}/group",
       "cmdType": "secured",
       "type": "POST",
       "accepts": "application/vnd.com.covisint.platform.group.v1+json",
       "contentType": "application/vnd.com.covisint.platform.group.v1+json"
     },
     {
       "cmd": "saveGroupWithTemplate",
       "call": "/group/v3/groups/{groupId}",
       "cmdType": "secured",
       "type": "PUT",
       "accepts": "application/vnd.com.covisint.platform.group.v1+json",
       "contentType": "application/vnd.com.covisint.platform.group.v1+json"
     },
     {
       "cmd": "getGroupsCount",
       "call": "/group/v3/groups/count",
       "cmdType": "secured",
       "type": "GET",
       "accepts": "text/plain"
     },
     {
       "cmd": "createGroup",
       "call": "/group/v2/groups",
       "type": "POST",
       "accepts": "application/vnd.com.covisint.platform.group.v1+json;includeEntitlements",
       "contentType": "application/vnd.com.covisint.platform.group.v1+json;includeEntitlements"
     },
     {
       "cmd": "getGroup",
       "call": "/group/v3/groups/{groupId}",
       "type": "GET",
       "cmdType": "secured",
       "accepts": "application/vnd.com.covisint.platform.group.v1+json;includeEntitlements"
     },
     {
       "cmd": "updateGroup",
       "call": "/group/v2/groups/{groupId}",
       "type": "PUT",
       "accepts": "application/vnd.com.covisint.platform.group.v1+json;includeEntitlements",
       "contentType": "application/vnd.com.covisint.platform.group.v1+json;includeEntitlements"
     },
     {
       "cmd": "getGroupEntitlements",
       "call": "/group/v2/groups/{groupId}/entitlements",
       "type": "GET",
       "accepts": "application/vnd.com.covisint.platform.group.entitlement.v1+json"
     },
     {
       "cmd": "createGroupEntitlement",
       "call": "/group/v2/groups/{groupId}/entitlements",
       "type": "POST",
       "accepts": "application/vnd.com.covisint.platform.group.entitlement.v1+json",
       "contentType": "application/vnd.com.covisint.platform.group.entitlement.v1+json"
     },
     {
       "cmd": "getGroupEntitlement",
       "call": "/group/v2/groups/{groupId}/entitlements/{groupEntitlementId}",
       "type": "GET",
       "accepts": "application/vnd.com.covisint.platform.group.entitlement.v1+json"
     },
     {
       "cmd": "deleteGroupEntitlement",
       "call": "/group/v2/groups/{groupId}/entitlements/{groupEntitlementId}",
       "type": "DELETE",
       "accepts": "application/vnd.com.covisint.platform.group.entitlement.v1+json"
     },
     {
       "cmd": "createGroupMemberships",
       "call": "/group/v3/groups/{groupId}/memberships",
       "cmdType": "secured",
       "type": "POST",
       "accepts": "application/vnd.com.covisint.platform.group.membership.v1+json",
       "contentType": "application/vnd.com.covisint.platform.group.membership.v1+json"
     },
     {
       "cmd": "getGroupMemberships",
       "call": "/group/v3/groups/{groupId}/memberships",
       "type": "GET",
       "cmdType": "secured",
       "accepts": "application/vnd.com.covisint.platform.group.membership.v1+json;includeGroupAndEntitlements;includeGroup"
     },
     {
       "cmd": "getGroupMembership",
       "call": "/group/v3/groups/{groupId}/memberships/{groupMembershipId}",
       "type": "GET",
       "accepts": "application/vnd.com.covisint.platform.group.membership.v1+json"
     },
     {
       "cmd": "deleteGroupMembership",
       "call": "/group/v3/groups/{groupId}/memberships/{groupMembershipId}",
       "type": "DELETE",
       "cmdType": "secured",
       "accepts": "application/vnd.com.covisint.platform.group.membership.v1+json"
     },
     {
       "cmd": "getMemberMemberships",
       "call": "/group/v3/memberships",
       "type": "GET",
       "cmdType": "secured",
       "accepts": "application/vnd.com.covisint.platform.group.membership.v1+json"
     },
     {
       "cmd": "activateGroup",
       "cmdType": "secured",
       "call": "/group/v3/groups/tasks/activate",
       "type": "POST"
     },
     {
       "cmd": "deactivateGroup",
       "cmdType": "secured",
       "call": "/group/v3/groups/tasks/deactivate",
       "type": "POST"
     },
     {
       "cmd": "getSecurityQuestions",
       "cmdType": "secured",
       "call": "/authn/v4/securityQuestions",
       "type": "GET",
       "accepts": "application/vnd.com.covisint.platform.securityquestion.v1+json"
     },
     {
       "cmd": "createSecurityQuestions",
       "call": "/authn/v4/securityQuestions/{personId}",
       "type": "PUT",
       "accepts": "application/vnd.com.covisint.platform.securityquestion.v1+json",
       "contentType": "application/vnd.com.covisint.platform.securityquestion.v1+json"
     },
     {
       "cmd": "updateSecurityQuestions",
       "call": "/authn/v4/securityQuestions/{personId}",
       "type": "PUT",
       "accepts": "application/vnd.com.covisint.platform.securityquestion.v1+json",
       "contentType": "application/vnd.com.covisint.platform.securityquestion.v1+json"
     },
     {
       "cmd": "authnNonceValidate",
       "cmdType": "unsecured",
       "call": "/authn/v4/authn/nonce/validate",
       "type": "PUT",
       "accepts": "application/vnd.com.covisint.platform.authn.nonce.v1+json",
       "contentType": "application/vnd.com.covisint.platform.authn.nonce.v1+json"
     },
     {
       "cmd": "sessionNonceValidate",
       "cmdType": "unsecured",
       "call": "/authn/v4/sessionToken/nonce/validate",
       "type": "POST",
       "accepts": "application/vnd.com.covisint.platform.nonce.request.v1+json",
       "contentType": "application/vnd.com.covisint.platform.nonce.request.v1+json"
     },
     {
       "cmd": "getSecurityQuestionAccount",
       "cmdType": "secured",
       "call": "/authn/v4/persons/{personId}/accounts/securityQuestion",
       "type": "GET",
       "accepts": "application/vnd.com.covisint.platform.person.account.securityQuestion.v1+json"
     },
     {
       "cmd": "createSecurityQuestionAccount",
       "call": "/authn/v4/persons/{personId}/accounts/securityQuestion",
       "type": "PUT",
       "accepts": "application/vnd.com.covisint.platform.person.account.securityQuestion.v1+json",
       "contentType": "application/vnd.com.covisint.platform.person.account.securityQuestion.v1+json"
     },
     {
       "cmd": "updateSecurityQuestionAccount",
       "call": "/authn/v4/persons/{personId}/accounts/securityQuestion",
       "type": "PUT",
       "accepts": "application/vnd.com.covisint.platform.person.account.securityQuestion.v1+json",
       "contentType": "application/vnd.com.covisint.platform.person.account.securityQuestion.v1+json"
     },
     {
       "cmd": "authenticatePassword",
       "call": "/authn/v4/passwords/tasks/authenticate",
       "type": "POST",
       "accepts": "application/vnd.com.covisint.platform.authn.password.resp.v1+json",
       "contentType": "application/vnd.com.covisint.platform.authn.password.resp.v1+json"
     },
     {
       "cmd": "lockPassword",
       "call": "/authn/v4/passwords/tasks/lock",
       "type": "POST",
       "accepts": "application/vnd.com.covisint.platform.password.v1+json",
       "contentType": "application/vnd.com.covisint.platform.password.v1+json"
     },
     {
       "cmd": "unlockPassword",
       "call": "/authn/v4/passwords/tasks/unlock",
       "type": "POST",
       "accepts": "application/vnd.com.covisint.platform.password.v1+json",
       "contentType": "application/vnd.com.covisint.platform.password.v1+json"
     },
     {
       "cmd": "getPasswordPolicies",
       "cmdType": "secured",
       "call": "/authn/v4/passwords/policies",
       "type": "GET",
       "accepts": "application/vnd.com.covisint.platform.password.policy.v1+json"
     },
     {
       "cmd": "createPasswordPolicies",
       "call": "/authn/v4/passwords/policies",
       "type": "POST",
       "accepts": "application/vnd.com.covisint.platform.password.policy.v1+json",
       "contentType": "application/vnd.com.covisint.platform.password.policy.v1+json"
     },
     {
       "cmd": "getPasswordPolicy",
       "cmdType": "secured",
       "call": "/authn/v4/passwords/policies/{policyId}",
       "type": "GET",
       "accepts": "application/vnd.com.covisint.platform.password.policy.v1+json"
     },
     {
       "cmd": "deletePasswordPolicy",
       "cmdType": "secured",
       "call": "/authn/v4/passwords/policies/{policyId}",
       "type": "DELETE",
       "accepts": "application/vnd.com.covisint.platform.password.policy.v1+json"
     },
     {
       "cmd": "getAttributes",
       "call": "/attributes/v1/attributes",
       "type": "GET",
       "accepts": "application/vnd.com.covisint.platform.attribute.v1+json"
     },
     {
       "cmd": "createAttribute",
       "call": "/attributes/v1/attributes",
       "type": "POST",
       "accepts": "application/vnd.com.covisint.platform.attribute.v1+json",
       "contentType": "application/vnd.com.covisint.platform.attribute.v1+json"
     },
     {
       "cmd": "getAttribute",
       "cmdType": "unsecured",
       "call": "/attributes/v1/attributes/{attributeId}",
       "type": "GET",
       "accepts": "application/vnd.com.covisint.platform.attribute.v1+json"
     },
     {
       "cmd": "updateAttribute",
       "cmdType": "unsecured",
       "call": "/attributes/v1/attributes/{attributeId}",
       "type": "PUT",
       "accepts": "application/vnd.com.covisint.platform.attribute.v1+json",
       "contentType": "application/vnd.com.covisint.platform.attribute.v1+json"
     },
     {
       "cmd": "deleteAttribute",
       "cmdType": "unsecured",
       "call": "/attributes/v1/attributes/{attributeId}",
       "type": "DELETE",
       "accepts": "application/vnd.com.covisint.platform.attribute.v1+json"
     },
     {
       "cmd": "getAttributeTemplates",
       "cmdType": "unsecured",
       "call": "/attributes/v1/attributeTemplates",
       "type": "GET",
       "accepts": "application/vnd.com.covisint.platform.attribute.template.v1+json"
     },
     {
       "cmd": "getAttributeTemplate",
       "cmdType": "unsecured",
       "call": "/attributes/v1/attributeTemplates/{attributeTemplateId}",
       "type": "GET",
       "accepts": "application/vnd.com.covisint.platform.attribute.template.v1+json"
     },
     {
       "cmd": "deleteAttributeTemplate",
       "cmdType": "unsecured",
       "call": "/attributes/v1/attributeTemplates/{attributeTemplateId}",
       "type": "DELETE",
       "accepts": "application/vnd.com.covisint.platform.attribute.template.v1+json"
     },
     {
       "cmd": "createAttributeTemplate",
       "cmdType": "unsecured",
       "call": "/attributes/v1/attributeTemplates/{attributeTemplateId}",
       "type": "POST",
       "accepts": "application/vnd.com.covisint.platform.attribute.template.v1+json",
       "contentType": "application/vnd.com.covisint.platform.attribute.template.v1+json"
     },
     {
       "cmd": "updateAttributeTemplate",
       "cmdType": "unsecured",
       "call": "/attributes/v1/attributeTemplates/{attributeTemplateId}",
       "type": "PUT",
       "accepts": "application/vnd.com.covisint.platform.attribute.template.v1+json",
       "contentType": "application/vnd.com.covisint.platform.attribute.template.v1+json"
     },
     {
       "cmd": "getAuthenticationPolicies",
       "cmdType": "secured",
       "call": "/authn/v4/policies",
       "type": "GET",
       "accepts": "application/vnd.com.covisint.platform.authn.policy.v1+json"
     },
     {
       "cmd": "getAuthenticationPolicy",
       "cmdType": "secured",
       "call": "/authn/v4/policies/{policyId}",
       "type": "GET",
       "accepts": "application/vnd.com.covisint.platform.authn.policy.v1+json"
     },
     {
       "cmd": "getOrganizationWithAttributes",
       "cmdType": "secured",
       "call": "/organization/v3/organizations/{organizationId}",
       "type": "GET",
       "accepts": "application/vnd.com.covisint.platform.organization.v1+json;getextendedattributes=true"
     },
     {
       "cmd": "getPersonDetailedStatusHistory",
       "cmdType": "secured",
       "call": "/person/v3/person/detailedStatusHistory",
       "type": "GET",
       "accepts": "application/vnd.com.covisint.platform.person.detail.status.history.v1+json"
     },
     {
       "cmd": "getPersonTacOfPackage",
       "cmdType": "unsecured",
       "call": "/service/v3/packages/{packageId}/persons/tacs",
       "type": "GET",
       "accepts": "application/vnd.com.covisint.platform.package.terms.and.condition.v1+json"
     },
     {
       "cmd": "getOrgTacOfPackage",
       "call": "/service/v3/packages/{packageId}/organizations/tacs",
       "type": "GET",
       "cmdType": "unsecured",
       "accepts": "application/vnd.com.covisint.platform.package.terms.and.condition.v1+json"
     },
     {
       "cmd": "getPersonTacOfPackageByLangId",
       "call": "/service/v3/packages/{packageId}/persons/tacs/languages/{languageId}",
       "type": "GET",
       "cmdType": "unsecured",
       "accepts": "application/vnd.com.covisint.platform.package.terms.and.condition.v1+json"
     },
     {
       "cmd": "getOrgTacOfPackageByLangId",
       "call": "/service/v3/packages/{packageId}/organizations/tacs/languages/{languageId}",
       "type": "GET",
       "cmdType": "unsecured",
       "accepts": "application/vnd.com.covisint.platform.package.terms.and.condition.v1+json"
     },
     {
       "cmd": "getPasswordCangeHistory",
       "cmdType": "secured",
       "call": "/person/v3/persons/{personId}/accounts/password/history",
       "type": "GET",
       "accepts": "application/vnd.com.covisint.platform.authn.password.change.history.req.v1+json"
     },
     {
       "cmd": "getPersonAppCategories",
       "cmdType": "secured",
       "call": "/service/v3/categories/persons/{personId} ",
       "type": "GET",
       "accepts": "application/vnd.com.covisint.platform.category.v1+json"
     },
     {
       "cmd": "getPersonPackagesGrantHistory",
       "call": "/service/v3/persons/{personId}/packages/history",
       "type": "GET",
       "accepts": "application/vnd.com.covisint.platform.package.grant.v1+json"
     },
     {
       "cmd": "getPersonApplicationsGrantHistory",
       "cmdType": "secured",
       "call": "/service/v3/applications/persons/{personId}/history",
       "type": "GET",
       "accepts": "application/vnd.com.covisint.platform.service.application.grant.history.v1+json"
     },
     {
       "cmd": "getPersonApplicationsRequestHistory",
       "cmdType": "secured",
       "call": "/service/v3/persons/{personId}/applications/requests/history",
       "type": "GET",
       "accepts": "application/vnd.com.covisint.platform.service.application.request.history.v1+json"
     },
     {
       "cmd": "getPersonApplicationsGrantHistoryCount",
       "cmdType": "secured",
       "call": "/service/v3/applications/persons/{personId}/history/count",
       "type": "GET",
       "accepts": "text/plain"
     },
     {
       "cmd": "getPersonApplicationsRequestHistoryCount",
       "cmdType": "secured",
       "call": "/service/v3/persons/{personId}/applications/requests/history/count",
       "type": "GET",
       "accepts": "text/plain"
     },
     {
       "cmd": "getPersonPendingApps",
       "call": "/service/v3/persons/{personId}/applications/requests",
       "type": "GET",
       "accepts": "application/vnd.com.covisint.platform.service.application.request.v1+json"
     },
     {
       "cmd": "getOrganizationsRequestableApps",
       "call": "/service/v3/applications/organizations/{organizationId}/requestable",
       "type": "GET",
       "accepts": "application/vnd.com.covisint.platform.service.application.v1+json"
     },
     {
       "cmd": "createOrgCompanyInvitation",
       "call": "/organization/v3/organizationInvitations/company/bulk",
       "type": "POST",
       "accepts": "application/vnd.com.covisint.platform.organization.invitation.v1+json",
       "contentType": "application/vnd.com.covisint.platform.organization.invitation.v1+json"
     },
     {
       "cmd": "createOrgDivisionInvitation",
       "call": "/organization/v3/organizationInvitations/division/bulk",
       "type": "POST",
       "accepts": "application/vnd.com.covisint.platform.organization.invitation.v1+json",
       "contentType": "application/vnd.com.covisint.platform.organization.invitation.v1+json"
     },
     {
       "cmd": "getOrganizationSecurityAdmins",
       "call": "/person/v3/persons/organizations/{organizationId}/securityAdmins",
       "type": "GET",
       "accepts": "application/vnd.com.covisint.platform.person.v1+json"
     },
     {
       "cmd": "getOrgAppCategories",
       "cmdType": "secured",
       "call": "/service/v3/categories/organization/{organizationId} ",
       "type": "GET",
       "accepts": "application/vnd.com.covisint.platform.category.v1+json"
     },
     {
       "cmd": "suspendPersonApp",
       "cmdType": "secured",
       "call": "/service/v3/grants/tasks/person/package/suspend",
       "type": "POST",
       "accepts": "application/vnd.com.covisint.platform.package.grant.status.request.v1+json",
       "contentType": "application/vnd.com.covisint.platform.package.grant.status.request.v1+json"
     },
     {
       "cmd": "unsuspendPersonApp",
       "cmdType": "secured",
       "call": "/service/v3/grants/tasks/person/package/unsuspend",
       "type": "POST",
       "accepts": "application/vnd.com.covisint.platform.package.grant.status.request.v1+json",
       "contentType": "application/vnd.com.covisint.platform.package.grant.status.request.v1+json"
     },
     {
       "cmd": "resetPersonPassword",
       "cmdType": "secured",
       "type": "POST",
       "accepts": "application/vnd.com.covisint.platform.password.v1+json",
       "contentType": " application/vnd.com.covisint.platform.password.v1+json",
       "call": "/authn/v4/passwords/tasks/reset"
     },
     {
       "cmd": "expirePersonPassword",
       "cmdType": "secured",
       "type": "POST",
       "accepts": "application/vnd.com.covisint.platform.password.v1+json",
       "contentType": "application/vnd.com.covisint.platform.password.v1+json",
       "call": "/authn/v4/passwords/tasks/expire"
     },
     {
       "cmd": "specifyPersonPassword",
       "cmdType": "secured",
       "type": "POST",
       "accepts": "application/vnd.com.covisint.platform.password.v1+json",
       "contentType": "application/vnd.com.covisint.platform.password.v1+json",
       "call": "/authn/v4/passwords/tasks/specify"
     },
     {
       "cmd": "revokePersonApp",
       "cmdType": "secured",
       "call": "/service/v3/persons/{personId}/packages/{packageId}",
       "type": "DELETE"
     },
     {
       "cmd": "retriveOrgPendingApps",
       "cmdType": "secured",
       "call": "/service/v3/organizations/requests",
       "type": "GET",
       "accepts": "application/vnd.com.covisint.platform.package.request.v1+json",
       "contentType": "application/vnd.com.covisint.platform.package.request.v1+json"
     },
     {
       "cmd": "getPersonRolesGrantable",
       "cmdType": "secured",
       "call": "/person/v3/persons/{personId}/roles/grantable",
       "type": "GET",
       "accepts": "application/vnd.com.covisint.platform.person.role.v1+json"
     },
     {
       "cmd": "assignPersonRoles",
       "cmdType": "secured",
       "call": "/person/v3/persons/roles",
       "type": "POST",
       "accepts": "application/vnd.com.covisint.platform.person.assign.role.v1+json",
       "contentType": "application/vnd.com.covisint.platform.person.assign.role.v1+json"
     },
     {
       "cmd": "unlockPersonPassword",
       "cmdType": "secured",
       "type": "POST",
       "accepts": "application/vnd.com.covisint.platform.password.v1+json",
       "contentType": " application/vnd.com.covisint.platform.password.v1+json",
       "call": "/authn/v4/passwords/tasks/unlock"
     },
     {
       "cmd": "retriveOrgPendingAppsCount",
       "cmdType": "secured",
       "call": "/service/v3/organizations/requests/count",
       "type": "GET",
       "accepts": "text/plain"
     },
     {
       "cmd": "getOrganizationPackagesCount",
       "cmdType": "secured",
       "call": "/service/v3/organizations/{organizationId}/packages/count",
       "type": "GET",
       "accepts": "text/plain"
     },
     {
       "cmd": "removePersonRoles",
       "cmdType": "secured",
       "call": "/person/v3/persons/remove/roles",
       "type": "POST",
       "contentType": "application/vnd.com.covisint.platform.person.delete.role.v1+json"
     },
     {
       "cmd": "suspendOrg",
       "cmdType": "secured",
       "call": "/organization/v3/organizations/tasks/suspend",
       "type": "POST"
     },
     {
       "cmd": "unsuspendOrg",
       "cmdType": "secured",
       "call": "/organization/v3/organizations/tasks/unsuspend",
       "type": "POST"
     },
     {
       "cmd": "removeOrg",
       "cmdType": "secured",
       "call": "/organization/v3/organizations/tasks/remove",
       "type": "POST"
     },
     {
       "cmd": "getOrgAppsGrantHistory",
       "call": "/service/v3/applications/organizations/{organizationId}/history",
       "type": "GET",
       "accepts": "application/vnd.com.covisint.platform.service.application.grant.history.v1+json"
     },
     {
       "cmd": "getOrgAppsRequestHistory",
       "call": "/service/v3/organizations/{organizationId}/applications/requests/history",
       "type": "GET",
       "accepts": "application/vnd.com.covisint.platform.service.application.request.history.v1+json"
     },
     {
       "cmd": "getOrgstatusHistory",
       "call": "/organization/v3/organizations/statusHistory",
       "type": "GET",
       "accepts": "application/vnd.com.covisint.platform.organization.status.history.v1+json"
     },
     {
       "cmd": "removePerson",
       "cmdType": "secured",
       "call": "/person/v3/persons/{personId}",
       "type": "DELETE"
     },
     {
       "cmd": "AddoAuth2",
       "cmdType": "secured",
       "call": "/social-accounts/v1/social",
       "type": "POST",
       "contentType": "application/json"
     },
     {
       "cmd": "getOAuth2",
       "cmdType": "secured",
       "call": "/social-accounts/v1/social",
       "type": "GET",
       "contentType": "application/json"
     },
     {
       "cmd": "getScimClientConfigurations",
       "cmdType": "secured",
       "call": "/scim/v2/ClientConfiguration",
       "type": "GET",
       "accepts": "application/json"
     },
     {
       "cmd": "createScimClientConfiguration",
       "cmdType": "secured",
       "call": "/scim/v2/ClientConfiguration",
       "type": "POST",
       "accepts": "application/json",
       "contentType": "application/json"
     },
     {
       "cmd": "deleteScimClientConfiguration",
       "cmdType": "secured",
       "call": "/scim/v2/ClientConfiguration/{clientId}",
       "type": "DELETE",
       "contentType": "application/json"
     },
     {
       "cmd": "addPackageClaims",
       "call": "/service/v3/claims",
       "type": "POST",
       "accepts": "application/vnd.com.covisint.platform.package.claim.v1+json",
       "contentType": "application/vnd.com.covisint.platform.package.claim.v1+json"
     },
     {
       "cmd": "updatePackageClaims",
       "call": "/service/v3/claims/{claimId}",
       "type": "PUT",
       "accepts": "application/vnd.com.covisint.platform.package.claim.v1+json",
       "contentType": "application/vnd.com.covisint.platform.package.claim.v1+json"
     },
     {
       "cmd": "deletePackageClaims",
       "call": "/service/v3/packages/{packageId}/claims/{claimId}",
       "type": "DELETE",
       "accepts": "application/vnd.com.covisint.platform.package.claim.v1+json",
       "contentType": "application/vnd.com.covisint.platform.package.claim.v1+json"
     },
     {
       "cmd": "deletePackageClaimsWithGrants",
       "call": "/service/v3/packages/{packageId}/claims/{claimId}",
       "type": "DELETE",
       "accepts": "application/vnd.com.covisint.platform.package.claim.v1+json;deleteuserpkggrant=true",
       "contentType": "application/vnd.com.covisint.platform.package.claim.v1+json"
     },
     {
       "cmd": "deletePackageClaimsValue",
       "call": "/service/v3/packages/{packageId}/claims/{claimId}/claimValue/{valueId}",
       "type": "DELETE",
       "accepts": "application/vnd.com.covisint.platform.package.claim.v1+json",
       "contentType": "application/vnd.com.covisint.platform.package.claim.v1+json"
     },
     {
       "cmd": "deletePackageClaimsValueWithGrants",
       "call": "/service/v3/packages/{packageId}/claims/{claimId}/claimValue/{valueId}",
       "type": "DELETE",
       "accepts": "application/vnd.com.covisint.platform.package.claim.v1+json;deleteuserpkgvaluegrant=true",
       "contentType": "application/vnd.com.covisint.platform.package.claim.v1+json"
     },
     {
       "cmd": "getScimConnectorConfigurations",
       "call": "/scim/v2/ConnectorConfiguration",
       "cmdType": "secured",
       "type": "GET",
       "accepts": "application/json",
       "contentType": "application/json"
     },
     {
       "cmd": "addScimConnectorConfiguration",
       "call": "/scim/v2/ConnectorConfiguration",
       "cmdType": "secured",
       "type": "POST",
       "accepts": "application/json",
       "contentType": "application/json"
     },
     {
       "cmd": "updateScimConnectorConfiguration",
       "call": "/scim/v2/ConnectorConfiguration/{id}",
       "cmdType": "secured",
       "type": "PUT",
       "accepts": "application/json",
       "contentType": "application/json"
     },
     {
       "cmd": "deleteScimConnectorConfiguration",
       "call": "/scim/v2/ConnectorConfiguration/{id}",
       "cmdType": "secured",
       "type": "DELETE",
       "accepts": "application/json",
       "contentType": "application/json"
     },
     {
       "cmd": "impersonateUser",
       "call": "/impersonate",
       "type": "POST",
       "contentType": "application/json"
     },
     {
       "cmd": "endImpersonation",
       "call": "/impersonate/stop",
       "type": "POST",
       "contentType": "application/json"
     },
     {
       "cmd": "getAvailableMfa",
       "call": "/authn/v4/mfa/persons/{id}/modes",
       "cmdType": "secured",
       "type": "GET",
       "accepts": "application/vnd.com.covisint.platform.mfa.user.modes.v1+json"
     },
     {
       "cmd": "getPreferedMfa",
       "call": "/mfa/v1/mfa/persons/{id}/mode",
       "cmdType": "secured",
       "type": "GET",
       "accepts": "application/vnd.com.covisint.platform.mfa.user.mode.v1+json"
     },
     {
       "cmd": "sendSMSCode",
       "call": "/mfa/v1/mfa/persons/{id}/token",
       "cmdType": "secured",
       "type": "POST",
       "accepts": "application/vnd.com.covisint.platform.mfa.user.v1+json"
     },
     {
       "cmd": "verifyMFA",
       "call": "/mfa/v1/mfa/persons/{id}/register",
       "cmdType": "secured",
       "type": "POST",
       "contentType": "application/vnd.com.covisint.platform.mfa.user.v1+json"
     },
     {
       "cmd": "sendEmailCode",
       "call": "/mfa/v1/mfa/persons/{id}/token?mode=EMAIL",
       "cmdType": "secured",
       "type": "POST",
       "accepts": "application/vnd.com.covisint.platform.mfa.user.v1+json"
     },
     {
       "cmd": "sendIDCipher",
       "call": "/mfa/v1/mfa/persons/{id}/token?mode=GRIDCARD",
       "cmdType": "secured",
       "type": "POST",
       "accepts": "application/vnd.com.covisint.platform.mfa.user.v1+json"
     },
     {
       "cmd": "sendNewIDCipher",
       "call": "/mfa/v1/mfa/persons/{id}/token?mode=GRIDCARD&action=SEND_GRID",
       "cmdType": "secured",
       "type": "POST",
       "accepts": "application/vnd.com.covisint.platform.mfa.user.v1+json"
     },
     {
       "cmd": "getTOTP",
       "call": "/mfa/v1/mfa/persons/{id}/token?mode=GA&action=SEND_GA",
       "cmdType": "secured",
       "type": "POST",
       "accepts": "application/vnd.com.covisint.platform.mfa.user.v1+json"
     },
     {
       "cmd": "callPhone",
       "call": "/mfa/v1/mfa/persons/{id}/token?mode=PHONE&phoneType=PHONE",
       "cmdType": "secured",
       "type": "POST",
       "accepts": "application/vnd.com.covisint.platform.mfa.user.v1+json"
     },
     {
      "cmd": "setMfaPolicy",
      "call": "/mfa/v1/mfa/persons/{id}/policy",
      "cmdType": "secured",
      "type": "POST",
      "accepts": "application/vnd.com.covisint.platform.mfa.user.policy.v1+json",
      "contentType": "application/vnd.com.covisint.platform.mfa.user.policy.v1+json"
    },
    {
      "cmd": "activateMfaPolicy",
      "call": "/mfa/v1/mfa/persons/{id}/policy/5b89476c-5d84-4d82-bf4c-fe75e119e91a/activate",
      "cmdType": "secured",
      "type": "POST",
      "accepts": "application/vnd.com.covisint.platform.mfa.user.policy.v1+json",
      "contentType": "application/vnd.com.covisint.platform.mfa.user.policy.v1+json"
    },
     {
       "cmd": "deleteOauth2",
       "call": "/social-accounts/v1/social/{name}",
       "cmdType": "secured",
       "type": "DELETE",
       "accepts": "application/json"
     },
     {
       "cmd": "makeCredentialsChallenge",
       "call": "/mfa/v1/fido/register/start",
       "type": "POST",
       "accepts": "application/json",
       "contentType": "application/json"
     },
     {
       "cmd": "sendWebAuthnResponse",
       "call": "/mfa/v1/fido/register/{id}/finish",
       "type": "POST",
       "accepts": "application/json",
       "contentType": "application/json"
     },
     {
       "cmd": "viewDevices",
       "call": "/mfa/v1/fido/device/search?cuid={id}",
       "type": "GET",
       "accepts": "application/json",
       "contentType": "application/json"
     },
     {
       "cmd": "deleteDevice",
       "call": "/mfa/v1/fido/device/{id}/{displayName}/delete",
       "type": "DELETE",
       "accepts": "application/json",
       "contentType": "application/json"
     },
     {
       "cmd": "countMfaPolices",
       "call": "/idmconfig/v1/mfa/policies/count",
       "type": "GET",
       "accepts": "application/json",
       "contentType": "application/json"
     },
     {
       "cmd": "getMfaPolices",
       "call": "/idmconfig/v1/mfa/policies",
       "type": "GET",
       "accepts": "application/json",
       "contentType": "application/json"
     },
     {
       "cmd": "deleteMfaPolicy",
       "call": "/idmconfig/v1/mfa/policies/{id}",
       "type": "DELETE",
       "accepts": "application/json",
       "contentType": "application/json"
     },
     {
       "cmd": "updateMfaPolicy",
       "call": "/idmconfig/v1/mfa/policies/{id}",
       "type": "PUT",
       "accepts": "application/json",
       "contentType": "application/json"
     },
     {
       "cmd": "addMfaPolicy",
       "call": "/idmconfig/v1/mfa/policies",
       "type": "POST",
       "accepts": "application/json",
       "contentType": "application/json"
     },
     {
       "cmd": "getMfaRiskCriteria",
       "call": "/idmconfig/v1/mfa/riskcriteria",
       "type": "GET",
       "accepts": "application/json",
       "contentType": "application/json"
     },
     {
       "cmd": "addMfaRiskCriteria",
       "call": "/idmconfig/v1/mfa/riskcriteria",
       "type": "POST",
       "accepts": "application/json",
       "contentType": "application/json"
     },
     {
       "cmd": "updateMfaRiskCriteria",
       "call": "/idmconfig/v1/mfa/riskcriteria/{criteriaId}",
       "type": "PUT",
       "accepts": "application/json",
       "contentType": "application/json"
     },
     {
       "cmd": "deleteMfaRiskCriteria",
       "call": "/idmconfig/v1/mfa/riskcriteria/{criteriaId}",
       "type": "DELETE",
       "accepts": "application/json",
       "contentType": "application/json"
     },
     {
       "cmd": "getSecurityQuestionListSize",
       "call": "/idmconfig/v1/properties?name=security.question.list.size",
       "type": "GET",
       "accepts": "application/json",
       "contentType": "application/json"
     },
     {
       "cmd": "getOrganizationGrantedApps",
       "accepts": "application/vnd.com.covisint.platform.service.application.v1+json",
       "call": "/service/v3/applications/organizations/{organizationId}",
       "type": "GET"
     },
     {
       "cmd": "initiateNonce",
       "cmdType": "unsecured",
       "type": "GET",
       "accepts": "application/json",
       "call": "/registration/v1/registrations/open/initiate"
     },
     {
       "cmd": "securedInitiate",
       "cmdType": "unsecured",
       "type": "GET",
       "accepts": "application/vnd.com.covisint.platform.person.invitation.v1+json",
       "call": "/registration/v1/registrations/securedInvite/initiate/{invitationId}"
     },
     {
       "cmd": "validateUsernameEmailNonce",
       "cmdType": "nonce",
       "type": "POST",
       "accepts": "application/vnd.com.covisint.platform.person.password.account.v1+json",
       "contentType": "application/vnd.com.covisint.platform.person.password.account.v1+json",
       "call": "/registration/v1/registrations/persons/registration/validate"
     },
     {
       "cmd": "getUserPreferance",
       "cmdType": "secured",
       "type": "GET",
       "accepts": "application/vnd.com.covisint.platform.event.v1+json",
       "contentType": "application/vnd.com.covisint.platform.event.v1+json",
       "call": "/notification/v1/notification/events"
     },
     {
       "cmd": "getPersonPreferance",
       "cmdType": "secured",
       "type": "GET",
       "accepts": "application/vnd.com.covisint.platform.person.notification.preferences.v1+json",
       "call": "/person/v3/persons/notification/preferences/{personId}"
     },
     {
       "cmd": "updatePersonPreference",
       "cmdType": "secured",
       "call": "/person/v3/persons/notification/preferences",
       "type": "POST",
       "accepts": "application/vnd.com.covisint.platform.person.notification.preferences.v1+json",
       "contentType": "application/vnd.com.covisint.platform.person.notification.preferences.v1+json"
     },
     {
       "cmd": "getPackageInfo",
       "cmdType": "unsecured",
       "type": "GET",
       "accepts": "application/vnd.com.covisint.platform.package.v1+json",
       "call": "/registration/v1/registrations/packages"
     },
     {
       "cmd": "getAttributeTemplateForPackage",
       "cmdType": "unsecured",
       "type": "GET",
       "accepts": "application/vnd.com.covisint.platform.attribute.template.v1+json",
       "contentType": "application/vnd.com.covisint.platform.attribute.template.v1+json",
       "call": "/attributes/v1/registration/attributeTemplates?type=PACKAGE_GRANT"
     },
     {
       "cmd": "getAttributeDetails",
       "cmdType": "unsecured",
       "type": "GET",
       "accepts": "application/vnd.com.covisint.platform.attribute.v1+json",
       "contentType": "application/vnd.com.covisint.platform.attribute.v1+json",
       "call": "/attributes/v1/registration/attributes/{attrId}"
     },
     {
       "cmd": "getTypeCode",
       "cmdType": "unsecured",
       "type": "GET",
       "accepts": "application/vnd.com.covisint.platform.package.claim.type.v1+json",
       "contentType": "application/vnd.com.covisint.platform.package.claim.type.v1+json",
       "call": "/service/v3/registration/type?type=CODE"
     },
     {
       "cmd": "getclaimTypes",
       "cmdType": "unsecured",
       "type": "GET",
       "accepts": "application/vnd.com.covisint.platform.package.claim.type.v1+json",
       "contentType": "application/vnd.com.covisint.platform.package.claim.type.v1+json",
       "call": "/service/v3/packages/{packageId}/claimTypes?type=CODE"
     },
     {
       "cmd": "getClaimsByType",
       "cmdType": "unsecured",
       "type": "GET",
       "accepts": "application/vnd.com.covisint.platform.package.claim.v1+json",
       "contentType": "application/vnd.com.covisint.platform.package.claim.v1+json",
       "call": "/service/v3/registration/packages/{packageId}/type/{claimType}/claims"
     },
     {
       "cmd": "createOrganization",
       "call": "/registration/v1/organization/registration/register",
       "cmdType": "unsecured",
       "type": "POST",
       "accepts": "application/vnd.com.covisint.platform.organization.v1+json",
       "contentType": "application/vnd.com.covisint.platform.organization.v1+json"
     },
     {
       "cmd": "postOrgRequestNonce",
       "cmdType": "unsecured",
       "type": "POST",
       "accepts": "application/vnd.com.covisint.platform.organization.request.v1+json",
       "contentType": "application/vnd.com.covisint.platform.organization.request.v1+json",
       "call": "/registration/v1/registrations/organizations/requests"
     },
     {
       "cmd": "postUserRegistrationNonce",
       "cmdType": "unsecured",
       "type": "POST",
       "accepts": "application/vnd.com.covisint.platform.person.password.account.v1+json",
       "contentType": "application/vnd.com.covisint.platform.person.password.account.v1+json",
       "call": "/registration/v1/registrations/persons/registration"
     },
     {
       "cmd": "postOrganizationRequestNonce",
       "cmdType": "unsecured",
       "type": "POST",
       "accepts": "application/vnd.com.covisint.platform.organization.request.v1+json",
       "contentType": "application/vnd.com.covisint.platform.organization.request.v1+json",
       "call": "/registration/v1/registrations/organizations/requests"
     },
     {
       "cmd": "getOrganizationNonce",
       "cmdType": "unsecured",
       "type": "GET",
       "accepts": "application/vnd.com.covisint.platform.organization.v1+json",
       "call": "/registration/v1/registrations/organizations/{organizationId}"
     },
     {
       "cmd": "postUserRegistrationNonce",
       "cmdType": "unsecured",
       "type": "POST",
       "accepts": "application/vnd.com.covisint.platform.person.password.account.v1+json",
       "contentType": "application/vnd.com.covisint.platform.person.password.account.v1+json",
       "call": "/registration/v1/registrations/persons/registration"
     },
     {
       "cmd": "postPersonRequestNonce",
       "cmdType": "unsecured",
       "type": "POST",
       "accepts": "application/vnd.com.covisint.platform.person.request.v1+json",
       "contentType": "application/vnd.com.covisint.platform.person.request.v1+json",
       "call": "/registration/v1/registrations/persons/requests"
     },
     {
       "cmd": "getPasswordPoliciesNonce",
       "cmdType": "nonce",
       "call": "/registration/v1/registrations/passwords/policies/{policyId}",
       "type": "GET",
       "accepts": "application/vnd.com.covisint.platform.password.policy.v1+json",
       "contentType":  "application/vnd.com.covisint.platform.password.policy.v1+json"
     },
     {
       "cmd": "getPersonPrivileges",
       "cmdType": "secured",
       "contentType": "application/vnd.com.covisint.platform.person.privilege.v1+json",
       "accepts": "application/vnd.com.covisint.platform.person.privilege.v1+json",
       "call": "/person/v3/persons/privileges/{personId}",
       "type": "GET"
     },
     {
       "cmd": "getClaimIdbyClaimValue",
       "cmdType": "unsecured",
       "type": "GET",
       "accepts": "application/vnd.com.covisint.platform.package.claim.v1+json",
       "contentType": "application/vnd.com.covisint.platform.package.claim.v1+json",
       "call": "/registration/v1/registrations/packages/{packageId}/type/{claimType}/claimValues?claimValueId={claimValue}"
     },
     {
       "cmd": "getAllTOPLevelPackages",
       "cmdType": "unsecured",
       "type": "GET",
       "accepts": "application/vnd.com.covisint.platform.package.v1+json",
       "call": "/registration/v1/registrations/packages?isParentPackage=TRUE"
     },
     {
       "cmd": "getPackagesByOrgId",
       "cmdType": "unsecured",
       "type": "GET",
       "accepts": "application/vnd.com.covisint.platform.service.application.v1+json",
       "call": "/registration/v1/applications/organizations/{orgId}"
     },
     {
       "cmd": "getOrgAdminCount",
       "call": "/service/v3/applications/persons/{personId}/count",
       "type": "GET",
       "accepts": "text/plain"
     },
     {
       "cmd": "getPersonApplications",
       "cmdType": "secured",
       "call": "/service/v3/applications/persons/{personId}",
       "type": "GET",
       "accepts": "application/vnd.com.covisint.platform.service.application.v1+json",
       "contentType": "application/vnd.com.covisint.platform.service.application.v1+json"
     },
     {
       "cmd": "getOrgPendingAppsCount",
       "cmdType": "secured",
       "call": "/service/v3/requests/count",
       "type": "GET",
       "accepts": "text/plain"
     },
     {
       "cmd": "getOrganizationPackagesCount",
       "cmdType": "secured",
       "call": "/service/v3/organizations/{organizationId}/packages/count",
       "type": "GET",
       "accepts": "text/plain"
     },
     {
       "cmd": "validateOrganizationName",
       "cmdType": "unsecured",
       "type": "GET",
       "accepts": "application/vnd.com.covisint.platform.organization.v1+json",
       "contentType": "application/vnd.com.covisint.platform.organization.v1+json",
       "call": "/registration/v1/registrations/organizations"
     },
     {
       "cmd": "SentReminderForPackage",
       "cmdType": "secured",
       "type": "POST",
       "accepts": "application/vnd.com.covisint.platform.package.request.v1+json",
       "contentType": "application/vnd.com.covisint.platform.package.request.v1+json",
       "call": "/service/v3/requests/tasks/remind"
     },
     {
       "cmd": "CancelPendingReqForPackage",
       "cmdType": "secured",
       "type": "DELETE",
       "call": "/service/v3/requests/{requestId}"
     },
     {
       "cmd": "getPackageClaimValuesByTypeClaimId",
       "cmdType": "secured",
       "type": "GET",
       "accepts": "application/vnd.com.covisint.platform.package.claim.v1+json",
       "contentType": "application/vnd.com.covisint.platform.package.claim.v1+json",
       "call": "/service/v3/packages/{packageId}/type/{typeId}/claimValues"
     },
     {
       "cmd": "createPackageClaimValueRequest",
       "call": "/service/v3/requests/claims",
       "type": "PUT",
       "accepts": "application/vnd.com.covisint.platform.package.request.v1+json",
       "contentType": "application/vnd.com.covisint.platform.package.claim.request.v1+json"
     },
     {
       "cmd": "getClaimsByOrgIdAndPackageId",
       "cmdType": "secured",
       "call": "/service/v3/organizations/{granteeOrgId}/packages/{packageId}/claims",
       "type": "GET",
       "accepts": "application/vnd.com.covisint.platform.package.grant.claim.v1+json"
     },
     {
       "cmd": "deleteclaimValueByClaimIdPackageIdOrgId",
       "call": "/service/v3/packages/organization/type/{claimType}/claims/values",
       "type": "PATCH",
       "contentType": "application/vnd.com.covisint.platform.package.grant.claim.v1+json",
       "accepts": "application/vnd.com.covisint.platform.package.grant.claim.v1+json"
     },
     {
       "cmd": "getAvaiableMfaModes",
       "cmdType": "secured",
       "call": "/mfa/v1/mfa/persons/{personId}/modes",
       "type": "GET",
       "accepts": "application/vnd.com.covisint.platform.mfa.user.modes.v1+json",
       "contentType": "application/vnd.com.covisint.platform.mfa.user.modes.v1+json"
     },
     {
       "cmd": "getSelectedMfaMode",
       "cmdType": "secured",
       "call": "/mfa/v1/mfa/persons/{personId}/mode",
       "type": "GET",
       "accepts": "application/vnd.com.covisint.platform.mfa.user.mode.v1+json",
       "contentType": "application/vnd.com.covisint.platform.mfa.user.mode.v1+json"
     },
     {
       "cmd": "createMfaToken",
       "cmdType": "secured",
       "call": "/mfa/v1/mfa/persons/{personId}/token",
       "type": "POST",
       "accepts": "application/vnd.com.covisint.platform.mfa.user.v1+json",
       "contentType": "application/vnd.com.covisint.platform.mfa.user.v1+json"
     },
     {
       "cmd": "deleteGoogleAccount",
       "cmdType": "secured",
       "call": "/mfa/v1/persons/{personId}/googleAccount",
       "type": "DELETE",
       "accepts": "application/json",
       "contentType": "application/json"
     },
     {
       "cmd": "registerMfaMode",
       "cmdType": "secured",
       "call": "/mfa/v1/mfa/persons/{personId}/register",
       "type": "POST",
       "accepts": "application/vnd.com.covisint.platform.mfa.tokens.v1+json",
       "contentType": "application/vnd.com.covisint.platform.mfa.tokens.v1+json"
     },
     {
       "cmd": "isMfaEnabled",
       "cmdType": "secured",
       "call": "/idmconfig/v1/properties?name=two.factor.auth.enabled",
       "type": "GET",
       "accepts": "application/vnd.com.covisint.platform.mfa.user.mode.v1+json",
       "contentType": "application/vnd.com.covisint.platform.mfa.user.mode.v1+json"
     },
     {
       "cmd": "getPersonPackagesCount",
       "cmdType": "secured",
       "call": "/service/v3/persons/{personId}/packages/count",
       "type": "GET",
       "accepts": "text/plain"
     },
     {
       "cmd": "getPasswordChangeHistory",
       "cmdType": "secured",
       "call": "/person/v3/persons/{userId}/accounts/password/history",
       "type": "GET",
       "accepts": "application/vnd.com.covisint.platform.authn.password.change.history.req.v1+json",
       "contentType": "application/vnd.com.covisint.platform.authn.password.change.history.req.v1+json"
     },
     {
       "cmd": "getServiceById",
       "cmdType": "secured",
       "call": "/service/v3/services/{id}",
       "type": "GET",
       "accepts": "application/vnd.com.covisint.platform.service.v1+json"
     },
     {
       "cmd": "getClaimValueDetailsByPackageIdAndClaimIdAndClaimValueId",
       "cmdType": "secured",
       "type": "GET",
       "accepts": "application/vnd.com.covisint.platform.package.claim.v1+json",
       "contentType": "application/vnd.com.covisint.platform.package.claim.v1+json",
       "call": "/service/v3/packages/{packageId}/type/{typeId}/claimValues"
     },
     {
       "cmd": "getClaimsByPersonIdAndPackageId",
       "cmdType": "secured",
       "call": "/service/v3/persons/{personId}/packages/{packageId}/claims",
       "type": "GET",
       "accepts": "application/vnd.com.covisint.platform.package.grant.claim.v1+json"
     },
     {
       "cmd": "grantRevokeClaimValueByClaimIdPersonId",
       "call": "/service/v3/packages/person/type/{claimType}/claims/values",
       "type": "PATCH",
       "contentType": "application/vnd.com.covisint.platform.package.grant.claim.v1+json",
       "accepts": "application/vnd.com.covisint.platform.package.grant.claim.v1+json"
     },
     {
       "cmd": "getPackageClaimTypeForCode",
       "cmdType": "unsecured",
       "type": "GET",
       "accepts": "application/vnd.com.covisint.platform.package.claim.type.v1+json",
       "contentType": "application/vnd.com.covisint.platform.package.claim.type.v1+json",
       "call": "/service/v3/packages/{packageId}/claimTypes?type=CODE"
     },
     {
       "cmd": "getPersonSubPackages",
       "cmdType": "secured",
       "call": "/service/v3/persons/{personId}/packages",
       "type": "GET",
       "accepts": "application/vnd.com.covisint.platform.package.grant.v1+json"
     },
     {
       "cmd": "revokeClaimIdPackageIdPersonId",
       "call": "/service/v3/packages/grants/revoke/type/{claimType}/claims",
       "cmdType": "secured",
       "type": "POST",
       "contentType": "application/vnd.com.covisint.platform.package.grant.claim.v1+json",
       "accepts": "application/vnd.com.covisint.platform.package.grant.claim.v1+json"
     },
     {
       "cmd": "assignAdminRights",
       "cmdType": "secured",
       "type": "POST",
       "accepts": "application/vnd.com.covisint.platform.package.resource.admin.v1+json",
       "contentType": "application/vnd.com.covisint.platform.package.resource.admin.v1+json",
       "call": "/service/v3/packages/{packageId}/serviceAdmin/add"
     },
     {
       "cmd": "removeAdminRights",
       "cmdType": "secured",
       "type": "POST",
       "accepts": "application/vnd.com.covisint.platform.package.resource.admin.v1+json",
       "contentType": "application/vnd.com.covisint.platform.package.resource.admin.v1+json",
       "call": "/service/v3/packages/{packageId}/serviceAdmin/remove"
     },
     {
       "cmd": "getServiceAdmins",
       "cmdType": "secured",
       "type": "GET",
       "accepts": "application/vnd.com.covisint.platform.person.v1+json",
       "call": "/service/v3/packages/{packageId}/admins/Service_Admin"
     },
     {
       "cmd": "createDivision",
       "cmdType": "secured",
       "call": "/organization/v3/organizations",
       "type": "POST",
       "accepts": "application/vnd.com.covisint.platform.organization.v1+json",
       "contentType": "application/vnd.com.covisint.platform.organization.v1+json"
     },
     {
       "cmd": "getPackageClaimTypeForRole",
       "cmdType": "unsecured",
       "type": "GET",
       "accepts": "application/vnd.com.covisint.platform.package.claim.type.v1+json",
       "contentType": "application/vnd.com.covisint.platform.package.claim.type.v1+json",
       "call": "/service/v3/packages/{packageId}/claimTypes?type=ROLE"
     },
     {
       "cmd": "getOrganizationPkgClaims",
       "cmdType": "secured",
       "type": "GET",
       "accepts": "application/vnd.com.covisint.platform.package.grant.claim.v1+json",
       "contentType": "application/vnd.com.covisint.platform.package.grant.claim.v1+json",
       "call": "/service/v3/organizations/{organizationId}/packages/{packageId}/claims"
     },
     {
       "cmd": "getMembers",
       "cmdType": "secured",
       "call": "/group/v3/memberships",
       "type": "GET",
       "accepts": "application/vnd.com.covisint.platform.group.membership.v1+json;includeGroupAndEntitlements;includeGroup"
     },
     {
       "cmd": "getClaimIdMetaData",
       "cmdType": "secured",
       "call": "/service/v3/packages/{packageId}/type/{claimType}/claims",
       "type": "GET",
       "accepts": "application/vnd.com.covisint.platform.package.claim.v1+json"
     },
     {
       "cmd": "getClaimsByPersonIdAndPackageIdForRoleType",
       "cmdType": "secured",
       "call": "/service/v3/persons/{personId}/packages/{packageId}/claims",
       "type": "GET",
       "accepts": "application/vnd.com.covisint.platform.package.grant.claim.v1+json"
     },
   
     {
       "cmd": "getRequestableClaimsForPackage",
       "cmdType": "secured",
       "call": "/service/v3/claims",
       "type": "GET",
       "accepts": "application/vnd.com.covisint.platform.package.claim.v1+json"
     },
     {
       "cmd": "getNewOrganisationRequests",
       "cmdType": "secured",
       "type": "GET",
       "accepts": "application/vnd.com.covisint.platform.organization.request.v1+json;charset=UTF-8",
       "contentType": "application/vnd.com.covisint.platform.organization.request.v1+json",
       "call": "/registration/v1/organization/requests?isApprovable=true"
     },
     {
       "cmd": "getOrganisationServicePackageRequests",
       "cmdType": "secured",
       "type": "GET",
       "accepts": "application/vnd.com.covisint.platform.package.request.v1+json",
       "contentType": "application/vnd.com.covisint.platform.package.request.v1+json",
       "call": "/service/v3/organizations/requests"
     },
     {
       "cmd": "getNewUserRequests",
       "cmdType": "secured",
       "type": "GET",
       "accepts": "application/vnd.com.covisint.platform.person.request.v1+json",
       "contentType": "application/vnd.com.covisint.platform.person.request.v1+json",
       "call": "/registration/v1/person/requests?isApprovable=true"
     },
     {
       "cmd": "getNewClaimRequests",
       "cmdType": "secured",
       "type": "GET",
       "accepts": "application/vnd.com.covisint.platform.package.request.v1+json;includedivision=true;includeclaimrequests=true;includeclaimvaluerequests=true",
       "contentType": "application/vnd.com.covisint.platform.package.request.v1+json;includedivision=true;includeclaimrequests=true;includeclaimvaluerequests=true",
       "call": "/service/v3/requests?isApprovable=true"
     },
     {
       "cmd": "getServicePackageRequestBasedonRequestId",
       "cmdType": "secured",
       "call": "/service/v3/requests",
       "type": "GET",
       "accepts": "application/vnd.com.covisint.platform.package.request.v1+json;includedivision=true;includeclaimrequests=true;includeclaimvaluerequests=true"
     },
     {
       "cmd": "approveServicePackageRequests",
       "cmdType": "secured",
       "call": "/service/v3/requests/tasks/approve",
       "type": "POST"
     },
     {
       "cmd": "denyServicePackageRequests",
       "cmdType": "secured",
       "call": "/service/v3/requests/tasks/deny",
       "type": "POST"
     },
     {
       "cmd": "approveClaimCodesRequests",
       "cmdType": "secured",
       "call": "/service/v3/requests/tasks/claim/approve",
       "type": "POST"
     },
     {
       "cmd": "denyClaimCodesRequests",
       "cmdType": "secured",
       "call": "/service/v3/requests/tasks/claim/deny",
       "type": "POST"
     },
     {
       "cmd": "approveOrgRequest",
       "cmdType": "secured",
       "type": "POST",
       "call": "/registration/v1/organization/requests/tasks/approve"
     },
     {
       "cmd": "denyOrgRequest",
       "cmdType": "secured",
       "type": "POST",
       "call": "/registration/v1/organization/requests/tasks/deny"
     },
     {
       "cmd": "getClaimRequestDetailsForOrg",
       "cmdType": "secured",
       "type": "GET",
       "accepts": "application/vnd.com.covisint.platform.package.request.v1+json;includedivision=true;includeclaimrequests=true;includeclaimvaluerequests=true",
       "contentType": "application/vnd.com.covisint.platform.package.request.v1+json;includedivision=true;includeclaimrequests=true;includeclaimvaluerequests=true",
       "call": "/service/v3/requests/{claimRequestId}"
     },
     {
       "cmd": "approveOrgClaimRequests",
       "cmdType": "secured",
       "type": "POST",
       "call": "/service/v3/requests/tasks/claim/approve"
     },
     {
       "cmd": "denyOrgClaimRequests",
       "cmdType": "secured",
       "type": "POST",
       "call": "/service/v3/requests/tasks/claim/deny"
     },
     {
       "cmd": "approveOrgClaimValueRequests",
       "cmdType": "secured",
       "type": "POST",
       "call": "/service/v3/requests/tasks/claimValue/approve"
     },
     {
       "cmd": "denyOrgClaimValueRequests",
       "cmdType": "secured",
       "type": "POST",
       "call": "/service/v3/requests/tasks/claimValue/deny"
     },
     {
       "cmd": "getPackagesManageRequests",
       "call": "/service/v3/packages",
       "type": "GET",
       "cmdType": "secured",
       "accepts": "application/vnd.com.covisint.platform.package.v1+json"
     },
     {
       "cmd": "getSaoOrgName",
       "cmdType": "unsecured",
       "call": "/registration/v1/registrations/organizations?isSAO=Y&packageId={packageId}",
       "type": "GET",
       "accepts": "application/vnd.com.covisint.platform.organization.v1+json"
     },
     {
       "cmd": "getGrantablePackages",
       "cmdType": "secured",
       "call": "/service/v3/applications/organizations/{orgId}/grantable",
       "type": "GET",
       "accepts": "application/vnd.com.covisint.platform.service.application.v1+json"
     },
     {
       "cmd": "getGrantablePackagesCount",
       "cmdType": "secured",
       "call": "/service/v3/applications/organizations/{orgId}/grantable/count",
       "type": "GET",
       "accepts": "text/plain"
     },
     {
       "cmd": "getPersonGrantablePackages",
       "cmdType": "secured",
       "call": "/service/v3/applications/persons/{personId}/grantable",
       "type": "GET",
       "accepts": "application/vnd.com.covisint.platform.service.application.v1+json"
     },
     {
       "cmd": "getPersonGrantablePackagesCount",
       "cmdType": "secured",
       "call": "/service/v3/applications/persons/{personId}/grantable/count",
       "type": "GET",
       "accepts": "text/plain"
     },
     {
       "cmd": "getOrgIncludedRequests",
       "cmdType": "secured",
       "type": "GET",
       "accepts": "application/vnd.com.covisint.platform.package.request.v1+json;includedivision=true;includeclaimrequests=true;includeclaimvaluerequests=true;",
       "call": "/service/v3/requests"
     },
     {
       "cmd": "approveUserRequest",
       "cmdType": "secured",
       "type": "POST",
       "call": "/registration/v1/person/requests/tasks/approve"
     },
     {
       "cmd": "denyUserRequest",
       "cmdType": "secured",
       "type": "POST",
       "call": "/registration/v1/person/requests/tasks/deny"
     },
     {
       "cmd": "getRolesForOrg",
       "cmdType": "secured",
       "call": "/role/v1/roles",
       "type": "GET",
       "accepts": "application/vnd.com.covisint.platform.role.v1+json"
     },
     {
       "cmd": "saveUsersToRole",
       "call": "/person/v3/persons/roles/bulk",
       "cmdType": "secured",
       "type": "POST",
       "accepts": "application/vnd.com.covisint.platform.person.assign.role.v1+json",
       "contentType": "application/vnd.com.covisint.platform.person.assign.role.v1+json"
     },
     {
       "cmd": "removeUsersFromRole",
       "call": "/person/v3/persons/remove/roles/bulk",
       "cmdType": "secured",
       "type": "POST",
       "accepts": "application/vnd.com.covisint.platform.person.delete.role.v1+json",
       "contentType": "application/vnd.com.covisint.platform.person.delete.role.v1+json"
     },
   
     {
       "cmd": "getNewClaimRequestsServiceClaimValue",
       "cmdType": "secured",
       "type": "GET",
       "accepts": "application/vnd.com.covisint.platform.package.request.v1+json;includedivision=true;includeclaimrequests=true;includeclaimvaluerequests=true",
       "contentType": "application/vnd.com.covisint.platform.package.request.v1+json;includedivision=true;includeclaimrequests=true;includeclaimvaluerequests=true",
       "call": "/service/v3/requests?isApprovable=true&type=servicePackageClaimValue"
     },
     {
       "cmd": "getNewClaimRequestsServiceClaim",
       "cmdType": "secured",
       "type": "GET",
       "accepts": "application/vnd.com.covisint.platform.package.request.v1+json;includedivision=true;includeclaimrequests=true;includeclaimvaluerequests=true",
       "contentType": "application/vnd.com.covisint.platform.package.request.v1+json;includedivision=true;includeclaimrequests=true;includeclaimvaluerequests=true",
       "call": "/service/v3/requests?isApprovable=true&type=servicePackageClaim"
     },
     {
       "cmd": "getServicePackageRequest",
       "cmdType": "secured",
       "type": "GET",
       "accepts": "application/vnd.com.covisint.platform.package.request.v1+json;includedivision=true;includeclaimrequests=true;includeclaimvaluerequests=true",
       "contentType": "application/vnd.com.covisint.platform.package.request.v1+json;includedivision=true;includeclaimrequests=true;includeclaimvaluerequests=true",
       "call": "/service/v3/requests?isApprovable=true"
     },
     {
       "cmd": "grantClaimValueByOrgId",
       "cmdType": "secured",
       "call": "/service/v3/packages/organization/type/{claimType}/claims/values",
       "type": "PATCH",
       "contentType": "application/vnd.com.covisint.platform.package.grant.claim.v1+json",
       "accepts": "application/vnd.com.covisint.platform.package.grant.claim.v1+json"
     },
     {
       "cmd": "addPersonClaims",
       "call": "/service/v3/packages/grants/type/{claimType}/claims",
       "cmdType": "secured",
       "type": "POST",
       "contentType": "application/vnd.com.covisint.platform.package.grant.claim.v1+json",
       "accepts": "application/vnd.com.covisint.platform.package.grant.claim.v1+json"
     },
     {
       "cmd": "removePackage",
       "call": "/service/v3/packages/{packageId}",
       "cmdType": "secured",
       "type": "DELETE"
     },
     {
       "cmd": "grantClaimValueByPersonId",
       "cmdType": "secured",
       "call": "/service/v3/packages/person/type/{claimType}/claims/values",
       "type": "PATCH",
       "contentType": "application/vnd.com.covisint.platform.package.grant.claim.v1+json",
       "accepts": "application/vnd.com.covisint.platform.package.grant.claim.v1+json"
     },
     {
       "cmd": "getUsersSearch",
       "cmdType": "secured",
       "type": "GET",
       "accepts": "application/vnd.com.covisint.platform.person.v1+json;includedivision=true;includelastlogindate=true;includeNotificationPrefs=true",
       "contentType": "application/vnd.com.covisint.platform.person.v1+json;includedivision=true;includelastlogindate=true;includeNotificationPrefs=true",
       "call": "/person/v3/persons"
     },
     {
       "cmd": "getPackageService",
       "cmdType": "secured",
       "call": "/service/v3/services/{serviceId}",
       "type": "GET",
       "accepts": "application/vnd.com.covisint.platform.service.v1+json"
     },
     {
       "cmd": "updatePackageService",
       "cmdType": "secured",
       "call": "/service/v3/services/{serviceId}",
       "type": "PUT",
       "accepts": "application/vnd.com.covisint.platform.service.v1+json",
       "contentType": "application/vnd.com.covisint.platform.service.v1+json"
     },
     {
       "cmd": "grantclaimId",
       "cmdType": "secured",
       "call": "/service/v3/packages/grants/type/{claimType}/claims",
       "type": "POST",
       "accepts": "application/vnd.com.covisint.platform.package.grant.claim.v1+json",
       "contentType": "application/vnd.com.covisint.platform.package.grant.claim.v1+json"
     },
     {
       "cmd": "getIncludedRequestsCount",
       "cmdType": "secured",
       "call": "/service/v3/requests/count",
       "type": "GET",
       "accepts": "text/plain"
     },
     {
       "cmd": "getOrganizationsTACForPackage",
       "cmdType": "secured",
       "call": "/service/v3/packages/{packageId}/organizations/tacs",
       "type": "GET",
       "accepts": "application/vnd.com.covisint.platform.package.terms.and.condition.v1+json"
     },
     {
       "cmd": "reacceptTAC",
       "call": "/service/v3/tacs/reaccept",
       "type": "POST",
       "cmdType": "secured",
       "accepts": "application/vnd.com.covisint.platform.package.terms.and.condition.v1+json",
       "contentType": "application/vnd.com.covisint.platform.tnc.v1+json"
     },
     {
       "cmd": "specifyPassword",
       "call": "/authn/v4/passwords/tasks/specify?expired=true",
       "type": "POST",
       "cmdType": "secured",
       "accepts": "application/vnd.com.covisint.platform.password.v1+json;isctcheckrequired=false",
       "contentType": "application/vnd.com.covisint.platform.password.v1+json"
     },
     {
       "cmd": "resetUserPassword",
       "call": "/authn/v4/passwords/tasks/reset",
       "type": "POST",
       "cmdType": "secured",
       "accepts": "application/vnd.com.covisint.platform.password.v1+json",
       "contentType": "application/vnd.com.covisint.platform.password.v1+json"
     },
     {
       "cmd": "createBatchRequest",
       "call": "/batchRequest/v1/batchRequest",
       "type": "POST",
       "cmdType": "secured",
       "cuiType": "sysAuth",
       "accepts": "application/vnd.com.covisint.platform.migrate.bulk.v1+json",
       "contentType": "application/vnd.com.covisint.platform.migrate.bulk.v1+json"
     },
     {
       "cmd": "getJobDetails",
       "call": "/batchRequest/v1/batchRequest/{jobId}",
       "type": "GET",
       "cmdType": "secured",
       "cuiType": "sysAuth",
       "accepts": "application/vnd.com.covisint.platform.migrate.bulk.v1+json"
     },
     {
       "cmd": "deletePersonRole",
       "call": "/person/v3/persons/remove/roles",
       "type": "POST",
       "cmdType": "secured",
       "accepts": "application/vnd.com.covisint.platform.person.delete.role.v1+json",
       "contentType": "application/vnd.com.covisint.platform.person.delete.role.v1+json"
     },
     {
       "cmd": "getAuditHistory",
       "cmdType": "secured",
       "call": "/iamaudit/v1/audits/{orgId}/history",
       "type": "GET",
       "contentType": "application/vnd.com.covisint.platform.audit.v1+json",
       "accepts": "application/vnd.com.covisint.platform.audit.v1+json"
     },
     {
       "cmd": "getJobDetails",
       "call": "/batchRequest/v1/batchRequest/{jobId}",
       "type": "GET",
       "cmdType": "secured",
       "cuiType": "sysAuth",
       "accepts": "application/vnd.com.covisint.platform.migrate.bulk.v1+json"
     },
     {
       "cmd": "logAudit",
       "cmdType": "secured",
       "call": "/iamaudit/v1/audits",
       "type": "POST",
       "contentType": "application/vnd.com.covisint.platform.audit.v1+json",
       "accepts": "application/vnd.com.covisint.platform.audit.v1+json"
     },
     {
       "cmd": "getPersonsWithLoginDateAndStatus",
       "cmdType": "secured",
       "call": "/person/v3/persons",
       "type": "GET",
       "accepts": "application/vnd.com.covisint.platform.person.v1+json;includedivision=false;lastlogindate=true;includeNotificationPrefs=true"
     },
     {
       "cmd": "auditGrantUserListing",
       "cmdType": "secured",
       "call": "/person/v3/persons",
       "type": "GET",
       "accepts": "application/vnd.com.covisint.platform.person.v1+json;includedivision=false",
       "contentType": "application/vnd.com.covisint.platform.person.v1+json;includedivision=false"
     },
     {
       "cmd": "revokeBatchAPI",
       "call": "/service/v3/grants/tasks/person/packages/{packageId}/revoke",
       "type": "POST",
       "cmdType": "secured",
       "accepts": "application/vnd.com.covisint.platform.bulk.response.v1+json",
       "contentType": "application/vnd.com.covisint.platform.package.grant.v1+json"
     },
     {
       "cmd": "revokeUserFromPackage",
       "cmdType": "secured",
       "call": "/service/v3/grants/tasks/person/packages/{packageId}/revoke",
       "type": "POST",
       "accepts": "application/vnd.com.covisint.platform.bulk.response.v1+json",
       "contentType": "application/vnd.com.covisint.platform.package.grant.v1+json"
     },
     {
       "cmd": "upassword",
       "cmdType": "unsecured",
       "type": "PUT",
       "accepts": "application/vnd.com.covisint.platform.person.account.password.v1+json",
       "contentType": "application/vnd.com.covisint.platform.person.account.password.v1+json",
       "call": "/person/v3/persons/82D6FEB07DFB4E73AA06CCDA4441D7BA/accounts/password"
     },
     {
       "cmd": "getPersonFavoritesServices",
       "cmdType": "secured",
       "type": "GET",
       "accepts": "application/vnd.com.covisint.platform.person.favorite.apps.v1+json",
       "contentType": "application/vnd.com.covisint.platform.person.favorite.apps.v1+json",
       "call": "/person/v3/persons/{cuid}/favorites"
     },
     {
       "cmd": "putPersonFavoritesServices",
       "cmdType": "secured",
       "type": "PUT",
       "accepts": "application/vnd.com.covisint.platform.person.favorite.apps.v1+json",
       "contentType": "application/vnd.com.covisint.platform.person.favorite.apps.v1+json",
       "call": "/person/v3/persons/{cuid}/favorites"
     },
     {
       "cmd": "deletePersonFavoritesServices",
       "cmdType": "secured",
       "type": "DELETE",
       "accepts": "application/vnd.com.covisint.platform.person.favorite.apps.v1+json",
       "contentType": "application/vnd.com.covisint.platform.person.favorite.apps.v1+json",
       "call": "/person/v3/persons/{cuid}/favorites/{serviceId}"
     }
   ]
 }