<button class="float-end" id="update-mfa" href="" type="button" hidden data-target="#update-mfa-modal"
    data-backdrop="static" data-toggle="modal">Open</button>


<div id="update-mfa-modal" class="modal fade" data-backdrop="static" data-keyboard="false" tabindex="-1"
    aria-hidden="false" aria-labelledby="update-mfa-modal" aria-modal="true" role="dialog">
    <div class="container">
        <div class="row">
            <div class="col col-lg-8 offset-lg-2">
                <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
                    <div class="modal-content bg-transparent border-0">
                        <div class="card card-body card-body-lg card-primary-top shadow pb-0 px-md-0 pt-6 pt-md-8">
                            <button type="button" class="close modal-close" data-dismiss="modal"
                                aria-label="Close Edit password information Modal" (click)="sendNotification()">
                                <span aria-hidden="true">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22"
                                        fill="none">
                                        <path d="M1.3999 1.3999L20.5999 20.5999M1.3999 20.5999L20.5999 1.3999"
                                            stroke="#0066FF" stroke-width="1.5" />
                                    </svg>
                                </span>
                            </button>
                            <div class="modal-body p-0">
                                <form [formGroup]="updateMfaForm" class="ot-form">
                                    <div class="container pb-6 pb-md-9">
                                        <div class="row">
                                            <div class="col col-md-10 offset-md-1">
                                                <h2 id="update-mfa-modal" class="mb-2 mb-md-3 mb-lg-4" translate>
                                                    mfa-mode
                                                </h2>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col col-md-10 offset-md-1">
                                                <div class="form-group ">
                                                    <label for="preferredMode" translate><span
                                                            class="text-danger mr-1">*</span>preferred-mode</label>
                                                    <select id="preferredMode" formControlName="preferredMode"
                                                        class=" form-control js-onchange-submit">
                                                        <option class="dropdown-item" value="" disabled selected hidden
                                                            translate>select
                                                        </option>
                                                        <option class="dropdown-item"
                                                            *ngFor="let mode of preferredModes | keyvalue"
                                                            value={{mode.value}} translate>{{mode.key}}
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- email mode selected  -->
                                        <div class="row" [hidden]="!this.ifEmailVisible">
                                            <div class="col col-md-10 offset-md-1">
                                                <div class="row align-items-center justify-content-between">
                                                    <div class="col-xl-6 col-lg-5 col-12 form-group" style="margin-bottom:10px;">
                                                        <label for="email" translate><span
                                                                class="text-danger mr-1">*</span>email</label>
                                                        <input type="email" class="form-control" name="email"
                                                            formControlName="email">
                                                        <div style="height: auto">
                                                            <div *ngIf="updateMfaForm.get('email')?.invalid  && updateMfaForm.get('email')?.touched  "
                                                                class="help-block">
                                                                <!-- <app-error-message [control2]="'email-required'"></app-error-message> -->
                                                                <p class="email-message"
                                                                    *ngIf="updateMfaForm.get('email')?.errors?.message === 'empty'">
                                                                    <small translate>email-empty</small>
                                                                </p>
                                                                <p class="email-message"
                                                                    *ngIf="updateMfaForm.get('email')?.errors?.message === 'format'">
                                                                    <small translate>email-required</small>
                                                                </p>
                                                            </div>
                                                            <p style="margin-bottom: 0px;"
                                                                *ngIf="updateMfaForm.get('email')?.valid"><small
                                                                    translate>verifaction-code-will-be-sent-to-mail</small>
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div *ngIf="this.updateMfaForm.get('preferredMode')?.value?.toUpperCase() === 'EMAIL' &&
                                                    this.editForm.get('preferredMode')?.value?.toUpperCase() === 'EMAIL' &&
                                                    this.modeUpdateRequired" class="col-xl-3 col-lg-4 col-6 custom-button">
                                                        <button class="btn btn-success" type="button" (click)="verifyCode()"
                                                            style="padding-left: 15px !important;text-align: center;width: max-content;"
                                                            translate>
                                                            <svg xmlns="http://www.w3.org/2000/svg" height="24px"
                                                                viewBox="0 0 24 24" width="24px" fill="#000000">
                                                                <path d="M0 0h24v24H0V0z" fill="none" />
                                                                <path
                                                                    d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm4.59-12.42L10 14.17l-2.59-2.58L6 13l4 4 8-8z" />
                                                            </svg>
                                                            &nbsp;&nbsp;verified</button>
                                                    </div>
                                                    <div *ngIf="this.updateMfaForm.get('preferredMode')?.value?.toUpperCase() === 'EMAIL' &&
                                                    this.editForm.get('preferredMode')?.value?.toUpperCase() === 'EMAIL' &&
                                                    this.modeUpdateRequired" class="col-lg-3 col-6 custom-button">
                                                        <button class="btn btn-outline-primary" type="button"
                                                            (click)="removeMode('EMAIL')" translate>remove</button>
                                                    </div>

                                                    <div *ngIf="!this.modeUpdateRequired"
                                                        class="col-lg-6 col-12 custom-button">
                                                        <button class="btn btn-primary" type="button"
                                                            style="margin-bottom: 5px;"
                                                            [disabled]="!updateMfaForm.get('email')?.valid"
                                                            (click)="validatePassCode('EMAIL'); this.emailButton='resend-passcode'"
                                                            translate>{{this.emailButton | translate}} &nbsp;
                                                            <app-spinner [enabled]="submitSpinner" [size]="'23px'"
                                                                [class]="'text-light'">
                                                            </app-spinner>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!--  phone or sms mode selected  -->

                                        <div class="row" [hidden]="!this.ifPhoneVisible ">
                                            <div class="col col-md-10 offset-md-1">
                                                <div class="row align-items-center">
                                                    <div class="col-lg-6 col-12 form-group"
                                                        style="margin-bottom: 10px;">
                                                        <label for="main" translate><span
                                                                class="text-danger mr-1">*</span>work-phone</label>
                                                        <input type="main" class="form-control" name="main"
                                                            formControlName="main">
                                                        <div style="height: auto">
                                                            <div *ngIf="updateMfaForm.get('main')?.invalid  && updateMfaForm.get('main')?.touched  || 
                                                            updateMfaForm.get('main')?.errors?.message === 'Invalid Phone Number'"
                                                                class="help-block">
                                                                <app-error-message
                                                                    [control2]="'phone-required'"></app-error-message>
                                                            </div>
                                                            <p style="margin-bottom: 0px;"
                                                                *ngIf="updateMfaForm.get('main')?.valid && updateMfaForm.get('preferredMode')?.value?.toUpperCase() === 'SMS'">
                                                                <small
                                                                    translate>verifaction-code-will-be-sent-as-text-message</small>
                                                            </p>
                                                            <p style="margin-bottom: 0px;"
                                                                *ngIf="updateMfaForm.get('main')?.valid && updateMfaForm.get('preferredMode')?.value?.toUpperCase() === 'PHONE'">
                                                                <small
                                                                    translate>verifaction-code-will-be-sent-by-phone-call</small>
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div *ngIf="this.editForm.get('preferredMode')?.value?.toUpperCase() === 'SMS'
                                                    && this.editForm.get('phoneVerifiedMode')?.value?.toUpperCase() === 'MAIN' 
                                                    && this.modeUpdateRequiredMain"
                                                        class="col-lg-3 col-6 custom-button">
                                                        <button class="btn btn-success" type="button"
                                                            style="padding-left: 15px !important;text-align: center;width: max-content;"
                                                            translate>
                                                            <svg xmlns="http://www.w3.org/2000/svg" height="24px"
                                                                viewBox="0 0 24 24" width="24px" fill="#000000">
                                                                <path d="M0 0h24v24H0V0z" fill="none" />
                                                                <path
                                                                    d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm4.59-12.42L10 14.17l-2.59-2.58L6 13l4 4 8-8z" />
                                                            </svg>
                                                            &nbsp;&nbsp;verified</button>
                                                    </div>
                                                    <div *ngIf="this.editForm.get('preferredMode')?.value?.toUpperCase() === 'SMS'
                                                    && this.editForm.get('phoneVerifiedMode')?.value?.toUpperCase() === 'MAIN' 
                                                    && this.modeUpdateRequiredMain"
                                                        class="col-lg-3 col-6 custom-button">
                                                        <button class="btn btn-outline-primary" type="button"
                                                            (click)="removeMode('MAIN')" translate>remove</button>
                                                    </div>
                                                    <div *ngIf="this.editForm.get('preferredMode')?.value?.toUpperCase() === 'PHONE'
                                                    && this.editForm.get('phoneVerifiedMode')?.value?.toUpperCase() === 'MAIN' 
                                                    && this.modeUpdateRequiredMain"
                                                        class="col-lg-3 col-6 custom-button" style="left: 12px;">
                                                        <button class="btn btn-success" type="button"
                                                            style="padding-left: 15px !important;text-align: center;width: max-content;"
                                                            translate>
                                                            <svg xmlns="http://www.w3.org/2000/svg" height="24px"
                                                                viewBox="0 0 24 24" width="24px" fill="#000000">
                                                                <path d="M0 0h24v24H0V0z" fill="none" />
                                                                <path
                                                                    d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm4.59-12.42L10 14.17l-2.59-2.58L6 13l4 4 8-8z" />
                                                            </svg>
                                                            &nbsp;&nbsp;verified</button>
                                                    </div>
                                                    <div *ngIf="this.editForm.get('preferredMode')?.value?.toUpperCase() === 'PHONE'
                                                    && this.editForm.get('phoneVerifiedMode')?.value?.toUpperCase() === 'MAIN' 
                                                    && this.modeUpdateRequiredMain"
                                                        class="col-lg-3 col-6 custom-button">
                                                        <button class="btn btn-outline-primary" type="button"
                                                            (click)="removeMode('MAIN')" translate>remove</button>
                                                    </div>

                                                    <div *ngIf="!this.modeUpdateRequiredMain"
                                                        class="col-lg-6 col-12 custom-button">
                                                        <button class="btn btn-primary" type="button"
                                                            style="margin-bottom: 5px;"
                                                            (click)="validatePassCode('MAIN'); this.mainPhoneButton ='resend-passcode'"
                                                            [disabled]="!updateMfaForm.get('main')?.valid"
                                                            translate>{{this.mainPhoneButton |
                                                            translate}} &nbsp;
                                                            <app-spinner [enabled]="submitSpinner" [size]="'23px'"
                                                                [class]="'text-light'">
                                                            </app-spinner>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row" [hidden]="!this.ifPhoneVisible">
                                            <div class="col col-md-10 offset-md-1">
                                                <div class="row align-items-center">
                                                    <div class="col-lg-6 col-12 form-group"
                                                        style="margin-bottom: 10px;">
                                                        <label for="mobile" translate><span
                                                                class="text-danger mr-1">*</span>mobile</label>
                                                        <input type="mobile" class="form-control" name="mobile"
                                                            formControlName="mobile">
                                                        <div style="height: auto">
                                                            <div *ngIf="this.updateMfaForm.get('mobile')?.invalid  && this.updateMfaForm.get('mobile')?.touched || 
                                                    this.updateMfaForm.get('mobile')?.errors?.message === 'Invalid Phone Number'"
                                                                class="help-block">
                                                                <app-error-message
                                                                    [control2]="'phone-required'"></app-error-message>
                                                            </div>
                                                            <p style="margin-bottom: 0px;"
                                                                *ngIf="updateMfaForm.get('mobile')?.valid && updateMfaForm.get('preferredMode')?.value?.toUpperCase() === 'SMS'">
                                                                <small
                                                                    translate>verifaction-code-will-be-sent-as-text-message</small>
                                                            </p>
                                                            <p style="margin-bottom: 0px;"
                                                                *ngIf="updateMfaForm.get('mobile')?.valid && updateMfaForm.get('preferredMode')?.value?.toUpperCase() === 'PHONE'">
                                                                <small
                                                                    translate>verifaction-code-will-be-sent-by-phone-call</small>
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div *ngIf="this.editForm.get('preferredMode')?.value?.toUpperCase() !== 'EMAIL' &&
                                                this.editForm.get('preferredMode')?.value?.toUpperCase() === 'SMS'
                                                && this.editForm.get('phoneVerifiedMode')?.value?.toUpperCase() === 'MOBILE' 
                                                && this.modeUpdateRequiredMobile" class="col-lg-3 col-6 custom-button">
                                                        <button class="btn btn-success" type="button"
                                                            style="padding-left: 15px !important;text-align: center;width: max-content;"
                                                            translate>
                                                            <svg xmlns="http://www.w3.org/2000/svg" height="24px"
                                                                viewBox="0 0 24 24" width="24px" fill="#000000">
                                                                <path d="M0 0h24v24H0V0z" fill="none" />
                                                                <path
                                                                    d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm4.59-12.42L10 14.17l-2.59-2.58L6 13l4 4 8-8z" />
                                                            </svg>
                                                            &nbsp;&nbsp;verified</button>
                                                    </div>
                                                    <div *ngIf="this.editForm.get('preferredMode')?.value?.toUpperCase() !== 'EMAIL' &&
                                            this.editForm.get('preferredMode')?.value?.toUpperCase() === 'SMS'&&
                                            this.editForm.get('phoneVerifiedMode')?.value?.toUpperCase() === 'MOBILE'  && 
                                            this.modeUpdateRequiredMobile" class="col-lg-3 col-6 custom-button">
                                                        <button class="btn btn-outline-primary" type="button"
                                                            (click)="removeMode('MOBILE')" translate>remove</button>
                                                    </div>
                                                    <div *ngIf="this.editForm.get('preferredMode')?.value?.toUpperCase() !== 'EMAIL' &&
                                            this.editForm.get('preferredMode')?.value?.toUpperCase() === 'PHONE'
                                            && this.editForm.get('phoneVerifiedMode')?.value?.toUpperCase() === 'MOBILE' 
                                            && this.modeUpdateRequiredMobile" class="col-lg-3 col-6 custom-button">
                                                        <button class="btn btn-success" type="button"
                                                            style="padding-left: 15px !important;text-align: center;width: max-content;"
                                                            translate>
                                                            <svg xmlns="http://www.w3.org/2000/svg" height="24px"
                                                                viewBox="0 0 24 24" width="24px" fill="#000000">
                                                                <path d="M0 0h24v24H0V0z" fill="none" />
                                                                <path
                                                                    d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm4.59-12.42L10 14.17l-2.59-2.58L6 13l4 4 8-8z" />
                                                            </svg>
                                                            &nbsp;&nbsp;verified</button>
                                                    </div>
                                                    <div *ngIf="this.editForm.get('preferredMode')?.value?.toUpperCase() !== 'EMAIL' &&
                                            this.editForm.get('preferredMode')?.value?.toUpperCase() === 'PHONE'&&
                                            this.editForm.get('phoneVerifiedMode')?.value?.toUpperCase() === 'MOBILE'  && 
                                            this.modeUpdateRequiredMobile" class="col-lg-3 col-6 custom-button">
                                                        <button class="btn btn-outline-primary" type="button"
                                                            (click)="removeMode('MOBILE')" translate>remove</button>
                                                    </div>

                                                    <div *ngIf="!this.modeUpdateRequiredMobile"
                                                        class="col-lg-6 col-12 custom-button">
                                                        <button class="btn btn-primary" type="button"
                                                            style="margin-bottom: 5px;"
                                                            [disabled]="!updateMfaForm.get('mobile')?.valid"
                                                            aria-placeholder="Enter your 6-digit Passcode"
                                                            (click)="validatePassCode('MOBILE'); this.mobilePhoneButton='resend-passcode'"
                                                            translate>{{this.mobilePhoneButton | translate}}
                                                            &nbsp; <app-spinner [enabled]="submitSpinnerMobile"
                                                                [size]="'23px'" [class]="'text-light'">
                                                            </app-spinner></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- GA mode selected  -->
                                        <div class="row" [hidden]="!this.ifGaVisible">
                                            <div class="col col-md-10 offset-md-1">
                                                <div class="row align-items-center">
                                                    <div *ngIf=!this.isGAVerified class="col-6 form-group"
                                                        style="margin-bottom: 10px;">
                                                        <div id="gaStep1">
                                                            <p class="content-step-1"
                                                                style="font-weight: bold;margin-bottom: 12px;"
                                                                translate>
                                                                <span class="content-step-1" translate>step-1 </span>
                                                                install-google-authenticator
                                                            </p>
                                                            <p style="margin-bottom: 1rem; text-align: left;" translate>
                                                                download-the-app-for-android-and-iOS-using-the-buttons-below
                                                            </p>
                                                            <div><a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
                                                                    target="_blank"><img class="play-store"
                                                                        style="width: 150px "
                                                                        src="assets/images/google-play-badge.png"
                                                                        alt="google play store"
                                                                        style="margin-right: 1rem;"></a><a
                                                                    href="https://apps.apple.com/us/app/google-authenticator/id388497605"
                                                                    target="_blank"><img
                                                                        src="assets/images/apple-store-badge.svg"
                                                                        alt="app store"></a></div>
                                                        </div>
                                                        <p class="content-step-1"
                                                            style="font-weight: bold;margin-bottom: 12px;" translate>
                                                            <span class="content-step-1" translate>step-2
                                                            </span>configure
                                                        </p>
                                                        <p style="margin-bottom: 1rem; text-align: left;" translate>
                                                            step2-instruction</p>
                                                    </div>
                                                    <div *ngIf=!this.isGAVerified class="col-6 form-group"
                                                        style="margin-bottom: 0px; text-align: center;">
                                                        <p class="content-step-1"
                                                            style="font-weight: bold;margin-bottom: 0px;">
                                                            <span class="content-step-1" translate>qr-code </span>
                                                        </p>
                                                        <img class="qr-code" style="width: 250px ; " src={{qrCodeImage}}
                                                            alt="google play store" style="margin-right: 1rem;">
                                                    </div>
                                                    <div *ngIf=this.isGAVerified style=" margin-left: 15px; ">
                                                        <p class="gaDeviceName" translate>
                                                            device-successfully-registered
                                                            <img _ngcontent-lww-c216="" src="assets/images/tickmark.svg"
                                                                alt="" class="imageSuccess">
                                                        </p>
                                                        <button class="btn btn-primary" type="button"
                                                            (click)="deleteGoogleAccount()" translate>remove
                                                            &nbsp; <app-spinner [enabled]="removeButton" [size]="'23px'"
                                                                [class]="'text-light'">
                                                            </app-spinner></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <br>
                                        <br>
                                        <!-- common for all modes -->
                                        <div class="row"
                                            *ngIf="!this.isGAVerified || this.updateMfaForm.get('preferredMode')?.value?.toUpperCase() !== 'GA' ">
                                            <div class="col col-md-10 offset-md-1">
                                                <div class="col-lg-6 col-12 form-group passcode p-0">
                                                    <label for="passCode" translate><span
                                                            class="text-danger mr-1">*</span>pass-code</label>
                                                    <input type="passCode" class="form-control" name="passCode"
                                                        type="password" formControlName="passCode">
                                                    <div style="height: 24px;">
                                                        <div *ngIf="this.updateMfaForm.get('passCode')?.invalid  && this.updateMfaForm.get('passCode')?.touched && this.updateMfaForm.get('passCode')?.value === ''"
                                                            class="help-block">
                                                            <app-error-message
                                                                [control2]="'passcode-required'"></app-error-message>
                                                        </div>
                                                        <div *ngIf="this.updateMfaForm.get('passCode')?.invalid  && this.updateMfaForm.get('passCode')?.dirty && this.updateMfaForm.get('passCode')?.errors?.message === 'invalidPasscode' "
                                                            class="help-block">
                                                            <app-error-message
                                                                [control2]="'passcode-incorrect'"></app-error-message>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col col-md-10 offset-md-1 p-0">
                                            <button class="btn btn-outline-primary" id="custom-modal-fade-add-close"
                                                type="button" data-dismiss="modal" translate hidden>close</button>
                                            <!-- modal on modal pop up -->
                                            <button class="btn btn-primary"
                                                *ngIf="this.updateMfaForm.get('preferredMode')?.value?.toUpperCase() !== 'GA'"
                                                type="button" [disabled]="!this.updateMfaForm.get('passCode')?.valid"
                                                (click)="validateOTP()" data-toggle="modal" translate>update &nbsp; <app-spinner
                                                    [enabled]="submitSpinnerModal" [size]="'23px'" [class]="'text-light'">
                                                </app-spinner></button>
        
                                            <button class="btn btn-primary"
                                                *ngIf="this.updateMfaForm.get('preferredMode')?.value?.toUpperCase() === 'GA'"
                                                type="button" [disabled]="!this.updateMfaForm.get('passCode')?.valid"
                                                (click)="validateOTPGA()" data-toggle="modal" translate>update &nbsp;
                                                <app-spinner [enabled]="submitSpinnerModal" [size]="'23px'"
                                                    [class]="'text-light'">
                                                </app-spinner></button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<!--warning modal invoked programitically if email is old -->
<button id="warning-modal-id" data-backdrop="static" class="btn btn-primary" type="button" data-target="#warning-modal"
    [hidden]="true" data-toggle="modal"> </button>




<div id="warning-modal" class="modal fade in" tabindex="-1" aria-hidden="false" aria-labelledby="my-modal">
    <div class="modal-dialog modal-dialog-centered ">
        <div class="modal-content rounded-0" style="height: auto;">
            <div class="modal-header">

                <h5 id="warning-modal" class="modal-title" translate>
                    <img class="icon" style="color: red; display: inline;"
                        src="assets/images/error_outline_black.svg" />&nbsp;warning
                </h5>
                <button class="close" (click)="cancelled()" type="button" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <p *ngIf="this.updateMfaForm.get('preferredMode')?.value?.toUpperCase() === 'EMAIL'" translate>
                    email-warning
                </p>
                <p *ngIf="this.updateMfaForm.get('preferredMode')?.value?.toUpperCase() === 'SMS' || 
this.updateMfaForm.get('preferredMode')?.value?.toUpperCase() === 'PHONE'" translate>
                    phone-warning
                </p>
            </div>

            <div class="modal-footer">
                <button id="warning-cancel" class="btn btn-outline-primary" type="button" data-dismiss="modal"
                    (click)="cancelled()" translate>cancel</button>
                <button class="btn btn-primary" (click)="sendPassCode()" type="button" translate>yes
                    &nbsp; <app-spinner [enabled]="submitSpinnerWarningModal" [size]="'23px'" [class]="'text-light'">
                    </app-spinner></button>
            </div>
        </div>
    </div>
</div>